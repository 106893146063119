<div class="s_l_section">
	<div class="my-h">
		<!-- Text and image section -->
		<h2 class="top_heading m-0 hide_element">Simplifying Time & Attendance Management</h2>

		<div class="hold_page_contnts_div p-4 d-flex row">
			
			<p class="w-100 text_one">The Most Efficient Way to keep track of your Time, Attendance & More
			</p>


			<section class="col-md-6 col-lg-6 m-0 p-0">
				<div class="hold_content">

					<div class="w-100 hold_image_text">
						<div class="app_log">
							<img src="../../../assets/img/paytime_logo-removebg-preview.png" />
						</div>
						<p class="w-100 text_two">Seamless. Smart. Simple.</p>
					</div>

					<div>
						<p class="w-100 text_three">Streamlining HR Management</p>
					</div>

				</div>
					<!-- <h3 class="bottom_heading">Join the many Organizations saving up to 50% or more on costs</h3> -->
			
			</section>

			<!-- Login Form Section -->
			<section class="col-md-6 col-lg-6 m-0 p-0 my-login">

				<div class="card my-5 box_Shadow w-45-fix">
					<div class="px-4 pb-1 text-center">
						<p class="text-muted m-0  p-3 ">{{ getcurrentURL() || "Sign In to your account" }}</p>
					</div>
					<form class="card-body pt-3" name="login" id="demo-form2" data-parsley-validate #f="ngForm"
						(ngSubmit)="login(f.form.valid)">
						<!-- Alert for invalid login -->
						<p *ngIf="alertMsg" class="alert alert-danger" style="width: 100% !important;">{{alertMsg}}</p>

						<!-- Username Input -->
						<div class="form-group" [ngClass]="{ 'has-error': submitted && !email.valid }">
							<label class="form-label" for="unq1">Username</label>
							<div class="input-group mb-4">
								<a class="input-group-text"><i class="fe fe-mail" aria-hidden="true"></i></a>
								<input class="form-control" name="email" [(ngModel)]="loginData.username"
									#email="ngModel" required="required" placeholder="Username" id="unq1">
								<div *ngIf="submitted && !email.valid" class="help-block">Please enter valid
									username.</div>
								<div *ngIf="isDiscontinue" class="help-block">You are no longer a user.</div>
							</div>
						</div>

						<!-- Password Input -->
						<div class="form-group" [ngClass]="{ 'has-error': submitted && !password.valid }">
							<label class="form-label" for="unq2">Password</label>
							<div class="input-group mb-4">
								<a class="input-group-text"><i class="fe fe-eye-off displayhidepassword"
										(click)="showPassword()" aria-hidden="true"></i></a>
								<input class="form-control" type="password" placeholder="Password" name="password"
									id="unq2" [(ngModel)]="loginData.password" #password="ngModel" minlength="6"
									required="required">
								<div *ngIf="submitted && !password.valid" class="help-block">Password is invalid.
									Minimum
									length is 6 characters</div>
							</div>
						</div>

						<!-- Submit Button -->
						<div class="submit">
							<button class="btn btn-primary btn-block" [disabled]="loading">Login</button>
						</div>

						<!-- Forgot Password Link -->
						<div class="text-center mt-3">
							<p class="mb-2"><a data-bs-toggle="modal" data-bs-target="#staticBackdrop"
									(click)="clearsubalertMsg()" style="cursor: pointer;">Forgot Password</a></p>
						</div>
					</form>
				</div>

			</section>
			<h3 class="bottom_heading">Join the many Organizations saving up to 50% or more on costs</h3>
			
		</div>
	</div>
</div>

<!-- Forgot Password Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
	aria-labelledby="staticBackdropLabel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="staticBackdropLabel">Forgot Password</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
			</div>
			<div class="modal-body">
				<div class="panel-body text-center">
					<p>New password will be sent to your registered email.</p>
					<div class="form-group">
						<input class="form-control" id="rusername" placeholder="Enter your Username" autocomplete="off"
							name="email">
					</div>
					<button class="btn btn-success" (click)="forgotpassword()">Send Password</button>
					<button class="btn btn-danger" data-bs-dismiss="modal">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</div>



	