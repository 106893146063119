import { Component, OnInit } from '@angular/core';
import { EmpsalaryslipService } from '../services/empsalaryslip.service';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeAttendenceProxyService } from '../services/employee-attendence-proxy.service';
import { IncometaxcalculatorServiceService } from '../services/incometaxcalculator-service.service';
import { IncomeTaxCalculator } from '../models/IncomeTaxCalculator';
import { IncomeTaxSavings } from '../models/IncomeTaxSaving';

@Component({
  selector: 'app-income-tax-calculator',
  templateUrl: './income-tax-calculator.component.html',
  styleUrls: ['./income-tax-calculator.component.css']
})
export class IncomeTaxCalculatorComponent implements OnInit {

  constructor(private incometaxcalculatorServiceService: IncometaxcalculatorServiceService,private empsalaryservice: EmpsalaryslipService,private empLeaveService: EmployeeLeaveService,private employeeService: EmployeeAttendenceProxyService) { }

  ngOnInit(): void {

  this.getAllBranches();
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 2018; year--) {
    this.years.push(`${year}-${year + 1}`); 
  }
    this.year = `${currentYear}-${currentYear+1}`;
 
  }

  months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];
  selectedMonth: { name: string, value: number } | undefined;
  years: string[] = [];
  directoryPath: string = '';
  year: any;
  selecteduniqueeName: any;
  selecteduniqueeEmpId:any;
  employeeList: any[] = [];
  savingList: any[] = [];
  query: any;
  queryName: any;
  employeeMaster: EmployeeMaster[];
  selectedempid: any;
  branch: any = "all";
  branchInfo: any;
  limitPara: any;
  offsetPara: any;
  clearChild: number = 0;
  selectedRegime: string = 'new';
  incomeTaxCalculator:IncomeTaxCalculator[]=[];

  downloadFile() {
    if(!this.year){
      console.log('year',this.year);
      return;
    }
    this.empsalaryservice.downloadIncomeTaxCalculator(this.year,this.selectedRegime,this.incomeTaxCalculator).subscribe(
      (data) => {
      //   if (!data) {
      //     this.alertmessages('No data available for the selected year and month.', 'danger');
      //     return;
      // }
      console.log("IncomeTaxCalculator", data);
      console.log("IncomeTaxCalculator", this.incomeTaxCalculator);
      var file = new Blob([data], { type: 'application/vnd.ms-excel' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "incomeTaxReport"+this.year+".xls";
      anchor.href = fileURL;
      anchor.click();
      },
      (error) => {
      //   if (error.status === 204) {
      //     this.alertmessages('No data available for the selected year and month.', 'danger');
      // } else {
      //     this.alertmessages('Error occurred while downloading the report.', 'danger');
      // }
        console.log("Error Occured ", error);
      }
    );
  }

  getEmployeeByNameOrId() {
    console.log("query", this.query)
    this.query = this.query.trim();
    console.log("Len ", this.query);
    if (this.query.length <= 2) {
      this.employeeMaster = []
    }
    if (this.query.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
        data => {
          console.log("Emp Data ", data);
          this.employeeMaster = data.employeeMasterData;
          this.selectedempid = this.employeeMaster[0].uniqueemployeeid;
        },
        error => {
          console.log("Error ", error);

        }
      )
    }
  }

  onBranchChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.branch = selectElement.value;
    console.log('Branch changed to: ', this.branch);
    // this.clearOnchange();
  }

  initializeIncomeTaxCalculator() {
    this.employeeList.forEach((employee, i) => {
      console.log("hello");
      this.incomeTaxCalculator[i] = {
        uniqueemployeeid: employee.uniqueemployeeid,
        otherPay: '',
        incomeTax: this.savingList.map(() => new IncomeTaxSavings())
      };
      console.log("IncomeTax for employee", i, this.incomeTaxCalculator[i]);
      console.log("IncomeTax for employee", i, this.incomeTaxCalculator[i].incomeTax);
    });
  }

  getAllBranches() {
    console.log("Called all branches >> >> >>")
    this.employeeService.getAllBranchs().subscribe(
      data => {
        console.log("check this")
        this.branchInfo = data.branches;
      },
      error => {
        console.log("Error ", error);
      }
    );
  }

  search() {
    this.offsetPara = 0;
    this.clearChild = 0; 
    console.log("before calling attendnace report function ", this.clearChild);
  }

  clear() {
    this.savingList=[];
    this.employeeList=[];
  }

  getIncomeTaxList() {
    console.log("query",this.query);
    if(this.query != undefined){
    const [name, id] = this.query.split(' / ');
    this.selectedempid =id;
    }
    this.incometaxcalculatorServiceService.getEmployeeList(this.selectedempid,this.branch,this.year).subscribe(
      data => {
        console.log("empdata",data)
        this.employeeList = data.getPeople;
        console.log("employee List",this.employeeList)
        this.getSavingMaster();
      },
      error => {
        console.log("Error ", error);
      }
    );
  }

  getSavingMaster(){
    this.incometaxcalculatorServiceService.getSavingMaster(this.selectedempid,this.branch,this.year).subscribe(
      data => {
        console.log("savingData",data)
        this.savingList = data.savingMaster;
        console.log("saving List",this.savingList)
        this.initializeIncomeTaxCalculator();
      },
      error => {
        console.log("Error ", error);
      }
    );
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement("div");
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`;
    let alertcomp = document.querySelector(".alertcomp");
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

}
