<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">

        </div>

        <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Apply Annual Leave Encashment</div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal form-label-left" novalidate>

                            <div class="x_content row">
                                <div class="col-md-4 col-sm-6 col-xs-6">
                                    <div class="form-group label-floating">
                                        <label class="form-label" for="asOnDate"> Applied On </label>

                                        <input disabled type="date" style="cursor: pointer;" [value]="applieddate"
                                            class="form-control fa-hand-pointer" id="asOnDate" name="applieddate" />
 
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-6">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Leave Type</label>

                                        <div class="form-group">
                                            <select required [ngModel]="empLeaveEncashInfo.leaveType"
                                                (ngModelChange)="onChangeLeaveTypes($event)" name="projects"
                                                class="form-control custom-select select2" id="leavetypes">
                                                <option label="Select" required></option>
                                                <option [ngValue]="leave.leaveType"
                                                    *ngFor="let leave of leaveTypesList">
                                                    {{leave.leaveName}}</option>
                                            </select>
                                            <div class="d-flex justify-content-between">
                                                <div style="padding-top: 5px; padding-bottom: 5px ; padding-right: 10px;"
                                                    id="leavetypesc"
                                                    [style.display]="i.leaveType === empLeaveEncashInfo.leaveType ? 'block':'none '"
                                                    *ngFor="let i of leaveTypesList" class="font-weight-semibold">Leave
                                                    Balance :
                                                    <!-- {{i.leaveName}}: -->

                                                    <span
                                                        class="badge badge-danger badge-pill ms-2">{{leaveBalance_Count}}</span>
                                                </div>
                                                <div style="padding-top :5px">
                                                    <label class="mb-0 font-weight-semibold text-dark">Encashable
                                                        Leave:</label>
                                                    <span
                                                        class="badge badge-danger badge-pill ms-2">{{totalLeaveCount}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <ng-container *ngIf="ifPersent">
                                    <div class="col-md-4 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label"> Number of Leave to Encash {{ifPersent}}</label>
                                            <div>
                                                
                                        <input type="text" style="cursor: pointer;" class="form-control fa-hand-pointer"
                                        id="noofLeave" name="noofLeave" [(ngModel)]="empLeaveEncashInfo.NOD" />
                    
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="!ifPersent">
                                    <div class="col-md-4 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label"> Number of Leave to Encash </label>
                                            <div>
                                                <input type="text" style="cursor: pointer;" class="form-control fa-hand-pointer"
                                                id="noofLeave" name="noofLeave" [(ngModel)]="empLeaveEncashInfo.NOD" disabled />
                            
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <!-- style="w-50 margin-right: 3vw"    style="w-50 margin-right: 3vw;"-->
 
                            </div>
                            <div>
                                <div class="col-md-4 col-sm-6 col-xs-6">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> &nbsp; </label>
                                        <div class="ms-auto">
                                            <button *ngIf="addOrUpdate; else updateButton" id="navigatetarget"
                                            class="btn btn-success" (click)="onSubmit()" 
                                                type="submit">Submit</button>
                                                <ng-template #updateButton>
                                                    <button type="submit" id="navigatetarget"
                                                        class="btn btn-success" 
                                                        (click)="onSubmit()">
                                                        Update
                                                    </button>
                                                </ng-template>
                                            <button type="button" (click)="onClear()"
                                                class="btn btn-danger ms-2">Clear</button>
                                            
                                        </div>
                                    </div>
                                </div>
                               
                            </div>

                        </form>
                    </div>

                </div>
                <!-- </div>
        </div> 
    </div> 
</div> -->

<div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="card-header border-bottom-0">
                                    <h3 class="card-title">Encashment History</h3>
                                </div>
                                <div class="card-body card1S p-0">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                            id="hr-table">
                                            <thead class="tablerow">
                                                <tr class="rowcolors stickyatTop">
                                                    <th class="border-bottom-0 w-5 fs-6 ">Employee Id
                                                    </th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Leave Type
                                                    </th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Applied Date
                                                    </th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">No of Days</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody style="max-height: 50vh; overflow: scroll;">
                                                <tr *ngFor="let el of encashmentList; let i = index">
                                                    <td>{{el.empId}}</td>
                                                    <td>{{el.leaveType }}</td>
                                                    <td>{{el.appliedDate | date }}</td>
                                                    <td>{{el.nod}}</td>
                                                    <td>
                                                        <button class="btn btn-primary btn-icon btn-sm me-2" (click)="editNoofDay(el)"
                                                          data-method="edit" title="Edit">
                                                          <i class="fa fa-edit" data-bs-toggle="tooltip" data-original-title="edit"></i>
                                                        </button></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</div>
            </div>
        </div>
    </div>



</div>