import { Component, OnInit } from '@angular/core';
import { LeaveEncashmentInfo } from '../models/employee-leave-encash-info.model';
import { SessionService } from '../services';
import { EmployeeLeaveEncashService } from '../services/employee.leave.encash.service';
import { AlertService } from 'src/app/services';
import { Router } from '@angular/router';
import { DatePipe, TitleCasePipe } from '@angular/common';

declare var $: any


@Component({
	selector: 'app-employee-leave-encash',
	templateUrl: './employee-leave-encash.component.html',
	styleUrls: ['./employee-leave-encash.component.css']
})
export class EmployeeLeaveEncashmentComponent implements OnInit {

	applieddate: string;
	addOrUpdate: boolean = true;
	empLeaveEncashInfo = new LeaveEncashmentInfo(-1, null, 1, "","");
	totalLeaveCount: number = 0;
	totalLeaveDates = [];
	leaveTypesList = [{ leaveType: "", leaveName: "" }]
	interleaveHoliday: boolean = false;
	leaveTypeMap = new Map();
	leaveBalance_Count: any;
	totalleave_count: any;
	noofdays: any;
	unqiueEmpid = Number(sessionStorage.getItem('empId'));
	leavetype: string;
	encashmentList: any[] = [];
	id: any;
	isEditing = false
	maxLeaveEncash: any;
	ifPersent: boolean;
	isLeavePresent : boolean;

	constructor(private empLeaveEncashService: EmployeeLeaveEncashService, private alertService: AlertService,
		private session: SessionService, private datepipe: DatePipe, private router: Router, private titleUpperCase: TitleCasePipe) {
		//const currentDate = new Date();
		// const year = currentDate.getFullYear();
		// const month = currentDate.getMonth() + 1;
		// this.applieddate = `${year}-${month.toString().padStart(2, '0')}-01`;
		let date = new Date();
		this.applieddate = date.toISOString().substring(0, 10);

	}
	ngOnInit(): void {
		var info = this.empLeaveEncashInfo;

		// const today = new Date();
		// const year = today.getFullYear();
		// const month = (today.getMonth() + 1).toString().padStart(2, '0');
		// const day = today.getDate().toString().padStart(2, '0');   

		// const today = new Date();
		this.resetNOD()
		this.getLeaves();
		this.getEncashmentLeaveApplied();
		//  this.getleaveBalance();

	}


	getLeaves() {

		this.empLeaveEncashService.getAllLeaveTypes().subscribe(

			data => {
				this.leaveTypesList = data.encashableLeaveType
				console.log("Data Leaves ", this.leaveTypesList);
				this.setLeaveMap();
				this.getleaveBalance();
			},
			error => {
				console.log("Error ", error);
			}
		)
	}

	onClear() {
		this.addOrUpdate = true;
		this.totalLeaveCount = 0;
		this.empLeaveEncashInfo = new LeaveEncashmentInfo(-1, null, 1, "","");
		document.getElementById('leavetypes')?.removeAttribute("empty")
		const nod = document.getElementById('noofLeave') as HTMLInputElement;
		nod.value = '';
		this.resetNOD()
	}

	setLeaveMap() {
		this.leaveTypesList.forEach(type => {
			this.leaveTypeMap.set(type.leaveType, type.leaveName)
		})
		console.warn(this.leaveTypeMap, "leave map")
		// this.getEmployeeLeaveOverview()
	}

	resetNOD() {
		this.empLeaveEncashInfo.NOD = null;

	}

	getLeaveName(leaveType: any) {
		console.warn(leaveType, "leave type", this.leaveTypeMap.get(leaveType))
		return this.leaveTypeMap.get(leaveType)
	}

	getleaveBalance() {
		let uempid = this.session.getempid();
		let date = new Date();
		this.empLeaveEncashInfo.AppliedDate = date.toISOString().substring(0, 10);
		let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
		let firstDayOfPreviousMonth = new Date(firstDay.getFullYear(), firstDay.getMonth() - 1, 1); //first day of previous month
		let leavetype = this.empLeaveEncashInfo.leaveType;
		// let indic = this.empLeaveEncashInfo.halfFullIndic; 

		//check opening leave-balance

		this.empLeaveEncashService.getleavebalance(Number(uempid), leavetype, this.datepipe.transform(this.empLeaveEncashInfo.AppliedDate, 'yyyy-MM-dd')).subscribe({
			next: (res) => {
				this.leaveBalance_Count = res.openingleaveBalance;
				this.totalLeaveCount = res.encashableLeave;
				this.maxLeaveEncash = res.maxLeaveEncash;

				console.log(" get leave balance function  ");
				console.log(" ");
				console.log(" leaveBalance_Count ", this.leaveBalance_Count);
				console.log(" totalLeaveCount ", this.totalLeaveCount);

				// let count = this.calculateLeave();

				if (this.empLeaveEncashInfo.NOD >= this.maxLeaveEncash) {


					this.alertmessages(" Can't apply for Encashment. You have no sufficient encashable leaves for this leave type.", "danger")
					this.onClear()
				}
				// else if (this.leaveBalance_Count < this.totalLeaveCount) {
				// 	this.alertmessages(" Can't apply for Encashmen. You have no sufficient leaves for this leave type.", "danger")
				// 	this.onClear()
				// }
				//this.alertmessages("Successfully Updated", "success");

			}, error: (err) => {
				console.log(err)

			}

		})
	}




	onSubmit() {

		console.log(" on submit 1 ");

		// this.empLeaveEncashInfo.empId = this.uniqueEmployeeId;
		this.empLeaveEncashInfo.empId = parseInt(this.session.getempid());
		let today = new Date();
		this.empLeaveEncashInfo.AppliedDate = today.toISOString().substring(0, 10);
		console.log("Submit Data ", this.empLeaveEncashInfo);

		this.noofdays = this.empLeaveEncashInfo.NOD;

		if (this.empLeaveEncashInfo.leaveType == "") {
			this.alertmessages("Please Select Leave Type", "danger")
			return;
		}
		if (this.noofdays == null || this.noofdays == 0) {
			this.alertmessages("Please Select Number of Days", "danger")
			return;
		}

		// Check if the selected date is a Sunday
		const selectedDate = new Date(this.empLeaveEncashInfo.AppliedDate);

		console.log(" on submit 2 ");

		console.log(this.empLeaveEncashInfo.AppliedDate);


		this.empLeaveEncashInfo.NOD = this.empLeaveEncashInfo.NOD.valueOf();

		console.log(" on submit  4 ");
		console.log(" ");
		console.log(" leaveBalance_Count ", this.leaveBalance_Count);
		console.log(" totalLeaveCount ", this.totalLeaveCount);

		this.unqiueEmpid = this.empLeaveEncashInfo.empId;
		this.applieddate = this.empLeaveEncashInfo.AppliedDate;

		this.leavetype = this.empLeaveEncashInfo.leaveType;
		if(this.noofdays > this.maxLeaveEncash ){
			this.alertmessages(" Can't apply for Encashment. You have only " + this.maxLeaveEncash + " leaves to encash", "danger")

			return;

		}

		this.empLeaveEncashService.saveApplyLeaveEncashment(this.unqiueEmpid, this.applieddate, this.noofdays, this.leavetype).subscribe(
			data => {


				this.alertmessages("Successfully updated", "success")
				this.onClear()
				this.resetNOD()
				this.getEncashmentLeaveApplied();

			},

		)

	}


	alertmessages(message: string, sign: string): void {
		let alertDiv = document.createElement('div');
		alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
	<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
		let alertcomp = document.querySelector('.alertcomp');
		alertcomp.append(alertDiv);
		document.body.scrollTop = document.documentElement.scrollTop = 0;

		setTimeout(function () {
			alertcomp.removeChild(alertDiv);
		}, 5000);
	}



	onChangeLeaveTypes(leaveType: any) {
		console.log("Leave Type ", leaveType);
		this.empLeaveEncashInfo.leaveType = leaveType

		console.log(this.leaveTypesList);


		this.getleaveBalance()
	}

	getEncashmentLeaveApplied() {
		this.empLeaveEncashService.getEncashmentLeaveApplied(this.unqiueEmpid).subscribe(data => {
			console.log(data);
			this.encashmentList = data.encashableLeaveDetail;

			console.log("encashment details :", this.encashmentList, "and length ", this.encashmentList.length);
			// if the leagnth is = 0 or emplty thne we should allow user to enter 
			if (this.encashmentList.length == 0) {
				this.ifPersent = true
				this.isLeavePresent = true;
				console.log("clicked edit leave type disable ", this.isLeavePresent);
			} else {
				this.ifPersent = false;
			}
		});
	}


	editNoofDay(info: any) {
		console.log("clicked edit ", this.ifPersent);
		this.ifPersent = true;
		console.log("clicked edit leave type disable ", this.isLeavePresent);
		this.isLeavePresent = false;
		this.addOrUpdate = false;  // on clik edit make submit button to update button
		this.empLeaveEncashInfo.empId = info.empId;
		this.empLeaveEncashInfo.AppliedDate = info.applieddate;
		this.empLeaveEncashInfo.NOD = info.nod;
		this.empLeaveEncashInfo.leaveType = info.leaveType;

		this.getleaveBalance();

	}

}
