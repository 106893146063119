<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <div class="alertcompExcelUpload"></div>
        <div class="card-body px-0">
            <div class="page-leftheader my-3">
              <div class="page-title">Income Tax Calculator</div>
            </div>
          </div>
            <div class="page-header d-xl-flex d-block">
                <div class="page-leftheader">
                    <div class="page-title">Download</div>
                </div>
            </div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
              <div class="card">
                <div class="card-body">
                    <div class="x_content row p-4">


                        <div class="col-md-4 col-sm-4 col-xs-4 mb-4">
                                    <label class="form-label w-100 mb-1">Employee
                                        Name / ID </label>
                                    <div class="form-group m-0">
                                        <div>

                                            <input type="search" class="form-control " name="employeename"
                                                id="employeename" [(ngModel)]="query" autocomplete="off"
                                                (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                                autofocus="autofocus" placeholder="Search Employee Name / ID">

                                                <!-- <datalist id="employeeMaster">
                                                    <option data-value={{emp.name +" / "+ emp.employeeid }} *ngFor="let emp of employeeMaster">
                                                        {{emp.employeeid}}
                                                    </option>
                                                </datalist> -->

                                                <datalist id="employeeMaster">
                                                    <option [value]="emp.name + ' / ' + emp.employeeid" *ngFor="let emp of employeeMaster" >
                                                        <!-- {{emp.name}} -->
                                                    </option>
                                                </datalist>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-4 col-xs-4 mb-4">
                                    <label class="form-label w-100 mb-1">Branch</label>
                                    <div class="form-group m-0">
                                        <div>
                                            <select id="deptInput" class="form-control" name="branch"
                                                placeholder="Select Branch" [(ngModel)]="branch"
                                                (change)="onBranchChange($event)" autocomplete="off"
                                                autofocus="autofocus">

                                                <option value="all" selected="selected">All</option>
                                                <option value={{data.branchId}} *ngFor="let data of branchInfo">
                                                    {{data.branchname}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>


                        <div class="col-md-4 col-sm-4 col-xs-4 mb-4">
                            <div class="form-group label-floating">
                                <label class="form-label"> Year </label>
                            <div>
                                <select id="year" class="form-control" [(ngModel)]="year" required="required"
                                #selectYear = "ngModel"
                                [ngClass]="(!selectYear.valid && selectYear.touched) ? 'is-invalid' : ''" >
                                    <option value="" selected>Select Year</option>
                                    <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                                </select>
                            </div>
                            <span class="help-text text-danger" *ngIf="(!selectYear.valid && selectYear.touched)">Please Select Year</span>
                                </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-4 mb-4">
                          <label class="form-label">Tax Regime</label>
                          <div class="form-group m-0 d-flex align-items-center">
                            <label class="form-check-label me-3">
                                <input type="radio" class="form-check-input" name="taxRegime" value="new" [(ngModel)]="selectedRegime"> New Regime
                            </label>
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="taxRegime" value="old" [(ngModel)]="selectedRegime"> Old Regime
                            </label>
                        </div>
                      </div>
                        <div class="col-md-3 col-sm-3 col-xs-3 mb-4">
                            <label class="form-label col-md-12 col-sm-4 col-xs-4 mb-1">&nbsp;</label>
                            <div class="form-group m-0">
                                <button type="button" id="navigatetarget" class="btn btn-primary w-45"
                                    style="margin-right: 1vw" (click)="search(); getIncomeTaxList();">
                                    Search
                                </button>
                                <button type="button" class="btn btn-danger w-45" (click)="clear()">
                                    Clear
                                </button>
                            </div>
                        </div>
                        
                        <!-- <div class="col-md-2 col-sm-2 col-xs-2">
                            <div class="form-group label-floating">
                                    <label></label>
                            <div>
                                <button type="button" class="btn btn-primary w-100 mt-1" (click)="selectYear.control.markAsTouched(); downloadFile()">Download</button>
                            </div>
                            </div>
                        </div> -->
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>

                    <div class="card">
                    <div class="card col-md-12 col-sm-12 col-xs-12">
                        <div class="card-header border-bottom-0">
                          <h3 class="card-title">List of Employee</h3>
                        </div>
                        <div class="card-body card1S p-0">
                            <div class="table-responsive">
                              <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                                <thead class="tablerow">
                                  <tr class="rowcolors stickyatTop">
                                    <th class="border-bottom-0 fs-7">Employee ID</th>
                                    <th class="border-bottom-0 fs-7">Employee Name</th>
                                    <th class="border-bottom-0 fs-7">Other Pay</th>
                                    <th *ngFor="let sav of savingList">{{ sav.name }}</th>
                                  </tr>
                                </thead>
                                <tbody style="max-height: 50vh; overflow: scroll; padding-bottom: 10vh;">
                                  <tr *ngFor="let emp of employeeList; let i = index">
                                    <td>{{ emp.uniqueemployeeid }}</td>
                                    <!-- <input type="hidden" [(ngModel)]="incomeTaxCalculator[i].uniqueemployeeid" [value]="emp.uniqueemployeeid "> -->
                                  
                                    <td>{{ emp.name }}</td>
                                    <td>
                                      <input type="text" [(ngModel)]="incomeTaxCalculator[i].otherPay" class="form-control">
                                    </td>
                                    <td *ngFor="let sav of savingList; let j = index">
                                      <ng-container *ngIf="incomeTaxCalculator[i].incomeTax.length!=0">
                                     
                                      <input type="text" [(ngModel)]="incomeTaxCalculator[i].incomeTax[j].amount" class="form-control" />
                                    </ng-container>
                                    </td>
                                  </tr>
                                </tbody>
                                
                              </table>
                            </div>
                          </div>
                      </div>

                      <div class="actionBar">
                        <div class="clearfix"></div>
                        <div class="pro-fab">
                          <button class="btn btn-success" type="button">
                            Save
                          </button>
                          <button class="btn btn-danger ms-2" type="button" (click)="downloadFile()">
                            Download
                          </button>
                        </div>
                      </div>
                      
                      </div>
                </div>
</div>
    