import { DatePipe } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { EmployeeAttendanceSummary } from '../models/EmployeeAttendanceSummary';
import { EmployeeAttendence } from '../models/EmployeeAttendenceInfo';
import { SessionService } from '../services';
import { EmployeeattendenceserviceService } from '../services/employeeattendenceservice.service';
import { GlobalConstants } from '../constants/GlobalConstants';
declare var $: any


@Component({
  selector: 'app-employee-attendence-summary',
  templateUrl: './employee-attendence-summary.component.html',
  styleUrls: ['./employee-attendence-summary.component.css']
})
export class EmployeeAttendenceSummaryComponent implements OnInit {

  currentdate: any;

  loggedUserName = this.sessionService.getloggedusername();
  fromDate: string;
  toDate: string;
  showTable: boolean = false;
  submitted = false;
  isEmptyF = false;
  isEmptyT = false;
  isFromGreater = false;
  sendId: any;
  selectedType: string = 'select';
  sendUniqueEmpId: number = null;

  uniqueEmpId = this.sessionService.getempid();
  empAttdSummaryList: EmployeeAttendanceSummary[];
  teamMemberList: EmployeeAttendenceSummaryComponent[];

  regularizedtemp: EmployeeAttendence[] = [];
  req_regularized_list: EmployeeAttendence[] = []
  userUniqueid: any;
  emp_attendence_list: any;
  swipeDate: any;

  swipeintype = GlobalConstants.SWIPE_IN;
  swipeouttype = GlobalConstants.SWIPE_OUT;

  constructor(private render: Renderer2, private empAttendenceService: EmployeeattendenceserviceService,
    private sessionService: SessionService, private datepipe: DatePipe) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

  }

  ngOnInit(): void {
    this.submitted = false;
    this.isEmptyF = false;
    this.isEmptyT = false;
    this.isFromGreater = false;
    // let defaultinfo = new EmployeeAttendence();
    // this.regularizedtemp.push(defaultinfo);
    this.viewTeam();


    this.render.listen(document.getElementById('navigatetarget'), 'click', () => {
      $('html,body').animate({
        scrollTop: $(`#navigatedest`).offset().top - 90
      },
        'slow');
    })

  }

  changeFromDate(selectedDate: any) {
    this.fromDate = selectedDate.target.value;
    this.isEmptyF = false

    if (this.isFromGreaterThanTo(this.fromDate, this.toDate)) {
      console.log("in");

      this.isEmptyF = false
      this.isFromGreater = true
    } else {
      console.log("out");

      this.isFromGreater = false
    }
  }

  isFromGreaterThanTo(from, to) {
    console.log(`from ${from} - to ${to}`);

    if (from !== undefined && to !== undefined) {
      return from > to;
    }
  }

  changeToDate(selectedDate: any) {
    this.toDate = selectedDate.target.value;
    this.isEmptyT = false

    if (this.isFromGreaterThanTo(this.fromDate, this.toDate)) {
      this.isEmptyF = false
      this.isFromGreater = true
    } else {
      this.isFromGreater = false
    }
  }

  reviewHandler() {
    console.log("Redirect to Regularize attendance page");

  }

  getEmpAttendanceSummary() {

    this.submitted = true;
    this.isEmptyF = false;
    this.isEmptyT = false;
    this.isFromGreater = false

    if (this.fromDate === undefined || this.fromDate === null || this.fromDate === '') {
      this.isEmptyF = true;
    }
    if (this.toDate === undefined || this.toDate === null || this.toDate === '') {
      this.isEmptyT = true;
    }

    if (this.isEmptyF || this.isEmptyT) {
      return
    }

    if (this.isFromGreaterThanTo(this.fromDate, this.toDate)) {
      this.isEmptyF = false
      this.isFromGreater = true
    }

    if (this.isFromGreater) {
      return
    }


    this.sendId = this.uniqueEmpId;
    console.log("logged id: " + this.sendId)



    if (this.sendUniqueEmpId != null) {

      this.sendId = this.sendUniqueEmpId;
      console.log("override  id: " + this.sendId)

    }




    console.log("Data to send : Server ", this.fromDate, this.toDate)
    this.empAttendenceService.getEmpAttendenceSummary(this.sendId, this.fromDate, this.toDate).subscribe(
      data => {
        this.empAttdSummaryList = data.attendanceSummary;
        if (this.empAttdSummaryList.length > 0) {
          this.showTable = true;
        }
        console.log("Attd Summary ", this.empAttdSummaryList)

        // this.scrollLength()
        $('html,body').animate({
          scrollTop: $(`#navigatedest`).offset().top - 90
        },
          'slow');
      },
      error => {
        // this.onClear()
        console.log("Error Attendance Summary ", error)
      }
    )


  }
  scrollLength() {
    console.log("dynamic height on scroll ");
    console.log("");
    const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
    const height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
    const computedHeight = height.match(regex);
    console.log("computed height", computedHeight, "computed height in no ", Number(computedHeight[1]), "height ", height)
    if (computedHeight && Number(computedHeight[1]) < 450) {
      let value: any = Number(computedHeight[1]);
      console.log("default value = ", value)
      value = value + (2 * 55);
      value = value + "px";
      console.log("final vl:", value)
      console.log("transition")
      document.getElementById("scrolllength").style.maxHeight = value
    }
    console.log("");
  }

  onClear() {
    this.fromDate = ""
    this.toDate = ""
    this.submitted = false;
    this.isEmptyF = false;
    this.isEmptyT = false;
    this.isFromGreater = false;
    this.selectedType = 'select';
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    $('#additionalModal').modal('toggle');
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 5000);
  }

  list_for_regularize: any[];
  checkedlistfor_regularize: any[];
  date_to_be_regularized: string;

  getswipeinfo(info) {

    this.regularizedtemp = [];

    console.log(info)
    this.userUniqueid = info.uniqueemployeeid;
    this.swipeDate = info.swipetime;
    this.currentdate = this.datepipe.transform(this.swipeDate, 'MMM d');
    console.log(this.list_for_regularize);
    this.regularizedtemp.push(new EmployeeAttendence())
  }


  submitforRegularize() {
    console.log(this.regularizedtemp)
    this.req_regularized_list = [...this.regularizedtemp];
    console.log("")
    console.log("required info = ", this.req_regularized_list)
    let flag = true;
    for (let i = 0; i < this.req_regularized_list.length; i++) {
      if (!(this.req_regularized_list[i].stime && Number(this.req_regularized_list[i].swipetype))) {
        this.alertmessages("Please Select the records you want to regularize.", "danger")
        flag = false
        return;
      }
    }
    console.log("required info");
    console.log(this.req_regularized_list)
    this.req_regularized_list[0].uniqueemployeeid = this.userUniqueid;
    if (flag) {
      console.log("this.selected type = ", this.selectedType)
      this.empAttendenceService.applyforRegularizeTeam(this.req_regularized_list, this.selectedType).subscribe({
        next: (res) => {
          // console.log(res)
          console.log("response on submit ", res)
          this.alertmessages("The Regularize attendance is approved .", "success");
          this.getEmpAttendanceSummary();

        },
        error: (error) => {
          this.alertmessages("Error occured while regularizing , Please try again ", "danger");
        }

      })
    }
    this.regularizedtemp = [];
  }

  clearall() {
    this.list_for_regularize = [];
    this.checkedlistfor_regularize = [];
    this.req_regularized_list = [];
    // let info = new EmployeeAttendence()
    // this.regularizedtemp.push(info)
    // console.log(this.regularizedtemp)
    this.regularizedtemp = [];
    this.date_to_be_regularized = ''
    $('#additionalModal').modal('toggle');
  }

  checkall(e) {
    const checkboxes = document.querySelectorAll("input");
    if (e.target.checked) {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == "checkbox") {
          checkboxes[i].checked = true;
          this.checkedlistfor_regularize = [...this.list_for_regularize];
        }
      }
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == "checkbox") {
          checkboxes[i].checked = false;
          this.checkedlistfor_regularize = [];
        }
      }
    }
  }

  add() {
    let info = new EmployeeAttendence()
    this.regularizedtemp.push(info)
  }

  check(info: EmployeeAttendence, e) {

    console.log(info, e)
  }

  getswipetime(info: EmployeeAttendence, e) {
    console.log(info, e)
    info.uniqueemployeeid = Number(this.sessionService.getempid());
    info.stime = this.datepipe.transform(e.target.value,
      'yyyy-MM-dd HH:mm:ss'
    )
    console.log(this.datepipe.transform(e.target.value,
      'yyyy-MM-dd HH:mm:ss'
    ))

  }

  getswipetype(info: EmployeeAttendence, e) {
    console.log(info, e)
    info.swipetype = e.target.value;
  }

  onChange(event) {
    this.selectedType = event.target.value;
    if (this.selectedType === 'Team_Member') {
      this.viewTeam();
      this.empAttdSummaryList = [];

    } else {
      this.sendId = this.uniqueEmpId;
      this.sendUniqueEmpId = null

      this.empAttdSummaryList = [];


    }
  }

  onChange1(event) {
    this.sendUniqueEmpId = event.target.value;
    this.getEmpAttendanceSummary();
    this.empAttdSummaryList = [];

  }

  viewTeam() {

    console.log("clicked team member ")
    this.empAttendenceService.getTeamMemberList(this.uniqueEmpId).subscribe(
      data => {

        this.teamMemberList = data.teamListSummary;
        console.log("team member list ", this.teamMemberList)
        // this.onClear()
      },
      error => {
        // this.onClear()
        console.log("Error team member list ", error)
      }
    )
  }

  get_Attendence_info(info) {
    console.log("view attendance_info on that day ")
    this.userUniqueid = info.uniqueemployeeid;
    this.swipeDate = info.swipetime;
    this.currentdate = this.datepipe.transform(this.swipeDate, 'MMM d');

    this.empAttendenceService.get_Attendence_info_based_On_date(this.userUniqueid, this.datepipe.transform(info.swipetime, 'yyyy-MM-dd')).subscribe(
      data => {
        this.emp_attendence_list = data.currentattendencelistbasedonDate;
        console.log(this.emp_attendence_list, "emp swipe info list for a given day")
        this.displayclockIn_and_clockOut(this.emp_attendence_list)

      }
    );
  }

  displayclockIn_and_clockOut(empswipelist: EmployeeAttendence[]) {
    if (empswipelist.length !== 0) {
      const lastswipeType: number = empswipelist[empswipelist.length - 1].swipetype;
      const clock_In = document.getElementById('clockIN');
      const clock_Out = document.getElementById('clockOut');

      const element_has_displaynone_class = document.querySelector('.displayNone');
      element_has_displaynone_class.classList.remove('displayNone')

      console.log(lastswipeType, "lastswipe type")
      if (lastswipeType === GlobalConstants.SWIPE_IN) {
        // display static clock
        if (clock_In.classList.contains('displayNone')) {
          clock_In.classList.remove('displayNone');

        }
        else {
          clock_In.classList.add('displayNone')
        }
      } else if (lastswipeType === GlobalConstants.SWIPE_OUT) {
        // display rotating clock indication that employee has clocked in 
        if (clock_Out.classList.contains('displayNone')) {
          clock_Out.classList.remove('displayNone');

        } else {
          clock_Out.classList.add('displayNone');
        }

      }

    }
  }

}
