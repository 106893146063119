import { Component, OnInit, ViewChild } from '@angular/core';
import { ruleDetailCredit } from '../models/RuleDetails';
import { EmpsalaryslipService } from '../services/empsalaryslip.service';
import { ITSlabDetails, subDetailsList } from '../models/ITSlabDetails';
import { NgForm } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-it-slab-master',
  templateUrl: './it-slab-master.component.html',
  styleUrls: ['./it-slab-master.component.css']
})
export class ITSlabMasterComponent implements OnInit {
gender :any;
employeeRuleDetail: any[] = [];
ITSlabMasterData:ITSlabDetails[];
insertITSlabDetails;
subDetailsInfo;
DeleteITSlabMaster:ITSlabDetails;
add_view_toggle: string = 'viewMaster';
addOrUpdate: boolean = true;


@ViewChild('subDetailsForm') subDetailsForm: NgForm;


  constructor(private empService: EmpsalaryslipService) { }

  ngOnInit(): void {
    this.insertITSlabDetails = new ITSlabDetails();
    this.subDetailsInfo = new subDetailsList();
    this.getITSlabMasterData();
  }


  
  
  clear()
  {
    this.insertITSlabDetails.lowerlimit ='';
    this.insertITSlabDetails.upperlimit = '';
    this.insertITSlabDetails.eduCessPercent = '';
    this.insertITSlabDetails.maleApplicable = '';
    this.insertITSlabDetails.femaleApplicable = '';
    this.insertITSlabDetails.selectRegimeId = '';
    this.employeeRuleDetail= [];
    this.clearSubDeatails()
  }
  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }
  
  clearSubDeatails() {
    this.subDetailsInfo.sublowerlimit = '';
    this.subDetailsInfo.subupperlimit= '';
    this.subDetailsInfo.subeducesspercent= '';
  }

  getITSlabMasterData() {
    console.log("IT slab master data1:" );
    this.empService.getItSlabMaster().subscribe((data) => {
      console.log(data);
      this.ITSlabMasterData = data.itSlabsMaster;  // edit and delete here 
    
      console.log("IT slab master data2:",  this.ITSlabMasterData);
     
    }, (error) => {
      console.log(error);
    });
  }
  viewMaster() {
    this.getITSlabMasterData();
    console.log("Clicked on view button")
    this.add_view_toggle = 'viewMaster';
    this.addOrUpdate = true;
    this.clear();
  }
  addMaster() {
    console.log("Clicked on add button")
    this.add_view_toggle = 'addMaster';
    this.addOrUpdate = true;
    this.clear();
  }

  updateMaster(insertITSlabDetails:any){
    console.log("insertITSlabDetails",insertITSlabDetails);

    console.log('combined list ', this.employeeRuleDetail);
    const obj1 = {
      sublowerlimit: this.subDetailsInfo.sublowerlimit === undefined ? 0 : this.subDetailsInfo.sublowerlimit,
      subupperlimit: this.subDetailsInfo.subupperlimit === undefined ? 0 : this.subDetailsInfo.subupperlimit,
      subeducesspercent: this.subDetailsInfo.subeducesspercent === undefined ? 0 : this.subDetailsInfo.subeducesspercent,
      userId: 'Add'
    }
    if(obj1.sublowerlimit!=0 && obj1.subupperlimit!=0 && obj1.subeducesspercent!=0 && obj1.sublowerlimit!=null && obj1.subupperlimit!=null && obj1.subeducesspercent!=null){
      this.employeeRuleDetail.push({ ...obj1 });
      this.clearSubDeatails();
      this.subDetailsForm.form.reset();
    }
    if(this.insertITSlabDetails.femaleApplicable){
      this.insertITSlabDetails.femaleApplicable = true;
    }else{
      this.insertITSlabDetails.femaleApplicable = false;
    }if(this.insertITSlabDetails.maleApplicable){
      this.insertITSlabDetails.maleApplicable = true;
    }else{
      this.insertITSlabDetails.maleApplicable = false;
    }
    

    this.insertITSlabDetails.subDetails= this.employeeRuleDetail;
    console.log("Updated insertITSlabDetails ", this.insertITSlabDetails);
    this.empService.updateItSlabMaster(insertITSlabDetails).subscribe(
      (data) => {
        console.log(data);
        
        this.getITSlabMasterData();
        this.clear();
       
        this.addOrUpdate = true;
        this.add_view_toggle = 'viewMaster';
        this.alertmessages("Successfully Updated", "success");
      }, (error) => {
        console.log(error)
        this.alertmessages("Please Try Again", "danger");
      });


  }

  editmaster(editId: any)
  {
    console.log("edited function ", editId);
    this.clear();
    this.empService.getEditItSlabMaster(editId).subscribe(
      (data) => {
        console.log(data);
        this.insertITSlabDetails = data.itSlabsMaster[0];
        console.log("this.insertITSlabDetails",this.insertITSlabDetails);
        console.log("data.itSlabsMaster[0].subDetails.id",data.itSlabsMaster[0].subDetails[0].id);
        if(data.itSlabsMaster[0].subDetails[0].id!=0){
        this.employeeRuleDetail = data.itSlabsMaster[0].subDetails
        }
        console.log("this.insertITSlabDetails",this.insertITSlabDetails);
        console.log("this.subDetailsInfoList",this.employeeRuleDetail);
        // this.getITSlabMasterData();
       
        this.addOrUpdate = false;  //for button change
        this.add_view_toggle = 'addMaster';
      }, (error) => {
        console.log(error)
        this.alertmessages("Please Try Again", "danger");
      });

    
    console.log("addOrUpdate ", this.addOrUpdate);
  }

  addEmployeeRule()
  {

    console.log("add employer comp ");
    console.log("this.subDetailsInfo",this.subDetailsInfo);
    const obj1 = {
      sublowerlimit: this.subDetailsInfo.sublowerlimit === undefined||null ? 0 : this.subDetailsInfo.sublowerlimit,
      subupperlimit: this.subDetailsInfo.subupperlimit === undefined||null ? 0 : this.subDetailsInfo.subupperlimit,
      subeducesspercent: this.subDetailsInfo.subeducesspercent === undefined||null ? 0 : this.subDetailsInfo.subeducesspercent,
      userId: 'Add'
    }
    console.log("this.subDetailsInfo",this.subDetailsInfo);
    
    // this.clearEmployerRule();

    const valuesToCheck = [
      obj1.sublowerlimit,
      obj1.subupperlimit,
      obj1.subeducesspercent,
    ];

    for (const value of valuesToCheck) {
      if (isNaN(value) || value < 0) {
        this.alertmessages("Please enter valid amount", "danger");
        this.subDetailsForm.form.reset();
        return;
      }
    }

    if (!obj1.sublowerlimit && !obj1.subupperlimit &&  !obj1.subeducesspercent ) {
      this.alertmessages("Please insert value for Sub Details", "danger");
      this.subDetailsForm.form.reset();
      return;
    }

    this.employeeRuleDetail.push({ ...obj1 });
    this.clearSubDeatails();
    console.log("addEmployeeRule Test", this.employeeRuleDetail);
    this.subDetailsForm.form.reset();
  }

 
  deleteEmployeeRule(index) {
    console.log(" slice obj from deleteEmployeeRule ", index);
    if (index !== null && index !== undefined) {

      console.log("this indez ", this.employeeRuleDetail[index]);
      this.employeeRuleDetail[index].userId = 'Del';
      this.employeeRuleDetail.splice(index, 1);
      console.log("List existing ", this.employeeRuleDetail);

    } else {
      console.error("Select Object to Delete!");
    }
  }

  deletemaster()
  {
    
    const deleteId =  this.DeleteITSlabMaster.id;
    console.log("deleteId", deleteId);
    this.empService.deleteItSlabMaster(deleteId).subscribe(
      (data) => {
        this.getITSlabMasterData();
        $('#showAlertVer').modal('toggle');
        this.alertmessages(data.successMessages[0]['key'], "success")
       
       
      }, (error) => {
        console.log(error)
        $('#showAlertVer').modal('toggle');
        this.alertmessages("Please Try Again", "danger");
        
      }
    );


  }

  onDeleteHandler(info: any) {
    console.log("delete : ", info);
    this.DeleteITSlabMaster=info;
    $('#showAlertVer').modal('toggle');
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');
    
    }

  submit() {
    console.log(" save ", this.insertITSlabDetails);

    // if (this.insertITSlabDetails.ruleName == undefined || this.insertITSlabDetails.ruleName.trim().length == 0) {
    //   this.alertmessages("Rule Name is Required", "danger");
    //   return;
    // }

    // if (this.insertPayCalRules.salaryHeadId == undefined || this.insertPayCalRules.salaryHeadId.trim().length == 0) {
    //   this.alertmessages("Salary Head Name is Required", "danger");
    //   return;
    // }

    // if (this.insertPayCalRules.salaryDetailId == undefined || this.insertPayCalRules.salaryDetailId.trim().length == 0) {
    //   this.alertmessages("Salary Detail Name is Required", "danger");
    //   return;
    // }

    // if (this.insertPayCalRules.percentTotalIndic == undefined || this.insertPayCalRules.percentTotalIndic.trim().length == 0) {
    //   this.alertmessages("Percent / Total Indic is Required", "danger");
    //   return;
    // }
    // if (this.insertPayCalRules.grossSelectIndic == undefined || this.insertPayCalRules.grossSelectIndic.trim().length == 0) {
    //   this.alertmessages("Gross Select Indic is Required", "danger");
    //   return;
    // }

    // const combinedList = [...this.employeeRuleDetail, ...this.employerRuleDetail, ...this.administrativeCharge];
   
    console.log('combined list ', this.employeeRuleDetail);
    const obj1 = {
      sublowerlimit: this.subDetailsInfo.sublowerlimit === undefined ? 0 : this.subDetailsInfo.sublowerlimit,
      subupperlimit: this.subDetailsInfo.subupperlimit === undefined ? 0 : this.subDetailsInfo.subupperlimit,
      subeducesspercent: this.subDetailsInfo.subeducesspercent === undefined ? 0 : this.subDetailsInfo.subeducesspercent,
      userId: 'Add'
    }
    if(obj1.sublowerlimit!=0 && obj1.subupperlimit!=0 && obj1.subeducesspercent!=0 && obj1.sublowerlimit!=null && obj1.subupperlimit!=null && obj1.subeducesspercent!=null){
      this.employeeRuleDetail.push({ ...obj1 });
      this.clearSubDeatails();
      this.subDetailsForm.form.reset();
    }
    if(this.insertITSlabDetails.femaleApplicable){
      this.insertITSlabDetails.femaleApplicable = true;
    }else{
      this.insertITSlabDetails.femaleApplicable = false;
    }if(this.insertITSlabDetails.maleApplicable){
      this.insertITSlabDetails.maleApplicable = true;
    }else{
      this.insertITSlabDetails.maleApplicable = false;
    }
    

    this.insertITSlabDetails.subDetails= this.employeeRuleDetail;
    console.log("insertITSlabDetails ", this.insertITSlabDetails);

    this.empService.saveItSlabMaster(this.insertITSlabDetails).subscribe(
      (data) => {
        console.log(data);
        this.getITSlabMasterData();
        this.clear();
        this.add_view_toggle = 'viewMaster';
        this.addOrUpdate = true;
        this.alertmessages("Successfully saved IT Slab Master", "success");
      }, (error) => {
        console.log(error)
        this.alertmessages("Please Try Again", "danger");
      });

  }
}
