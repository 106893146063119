<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>

        <div class="card-body px-0">
            <div class="page-leftheader my-3">
                <div class="page-title">Savings Master</div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left row"  #savingsmasterform="ngForm">  <!-- <form id="fcForm" class="form-horizontal form-label-left row" #expenseform="ngForm"> -->
                            <div class="col-md-4 col-sm-4 col-xs-4">
                                <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12" >
                                    <label class="form-label">Name</label>
                                        <div>
                                        <input type="text" name="savingMastername" class="form-control col-md-12 col-xs-12" autocomplete="off"
                                          autofocus="true" [(ngModel)]="name" required  
                                          #savingMastername="ngModel" [ngClass]="(!savingMastername.valid && savingMastername.touched) ? 'is-invalid' : ''"/>
                                      </div>
                                      <span class="help-text text-danger" *ngIf="(!savingMastername.valid && savingMastername.touched)">Please Enter Name </span> 
                                </div>
                            </div>

                            <div class="col-md-4 col-sm-4 col-xs-4">    
                                <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12">
                                    <label class="form-label">% to be considered for saving</label>
                                        <div>
                                        <input type="number" name="savings" class="form-control col-md-12 col-xs-12" autocomplete="off"
                                          autofocus="true"[(ngModel)]="saving"  required  min="0"
                                          #savings="ngModel" [ngClass]="(!savings.valid && savings.touched) ? 'is-invalid' : ''"/>
                                      </div>
                                      <span class="help-text text-danger" *ngIf="(!savings.valid && savings.touched)">Please Enter % to be considered for saving</span>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-4">
                               
                                <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12">
                                    <label class="form-label">Maximum Savings Amount considered - Male</label>
                                        <div>
                                        <input type="number" name="maxSavingsMale" class="form-control col-md-12 col-xs-12" autocomplete="off"
                                           autofocus="true" [(ngModel)]="maxamountmale" required  min="0"
                                           #maxSavingsMale="ngModel" [ngClass]="(!maxSavingsMale.valid && maxSavingsMale.touched) ? 'is-invalid' : ''"/>
                                      </div>
                                       <span class="help-text text-danger" *ngIf="(!maxSavingsMale.valid && maxSavingsMale.touched)">Please Enter Maximum Savings Amount considered - Male </span>
                                </div>
                            </div>

                            <div class="col-md-4 col-sm-4 col-xs-4">
                                <div class="form-group label-floating">
                                    <label class="form-label">Maximum Savings Amount considered - Female</label>
                                        <div>
                                        <input type="number" name="maxSavingsFemale" class="form-control col-md-12 col-xs-12" autocomplete="off"
                                          autofocus="true"  [(ngModel)]="maxamountfemale" required min="0"
                                          #maxSavingsFemale="ngModel" [ngClass]="(!maxSavingsFemale.valid && maxSavingsFemale.touched) ? 'is-invalid' : ''"/>
                                      </div>
                                     <span class="help-text text-danger" *ngIf="(!maxSavingsFemale.valid && maxSavingsFemale.touched)">Please Enter Maximum Savings Amount considered - Female </span> 
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 col-xs-6 justify-content-between align-items-start">
                                <div class="form-group label-floating">
                                    <label class="form-label">&nbsp;</label>
                                  
                                    <button  *ngIf="addOrUpdate; else updateButton" type="button" id="navigatetarget"
                                    class="btn btn-primary w-40" style="margin-right: 3vw;"(click)="addsavingsmaster()" >
                                    Add
                                </button>
                                    <ng-template #updateButton>
                                            <button type="number" id="navigatetarget" class="btn btn-primary w-40" (click)="updatesavingsmaster()"
                                            style="margin-right: 3vw;">
                                            Update
                                        </button>
                                    </ng-template>
                                    <button type="button" class="btn btn-danger w-40" (click)="clear()">
                                        
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="card col-md-12 col-sm-12 col-xs-12">
            <div class="card-header border-bottom-0">
                <h3 class="card-title">List Of Savings Master</h3>
            </div>
            <div class="card-body card1S p-0">
                <div class="table-responsive">
                    <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                        id="hr-table">
                        <thead class="tablerow">
                            <tr class="rowcolors stickyatTop">
                                <th class="border-bottom-0 w-5 fs-6 ">Name</th>
                                <th class="border-bottom-0 w-5 fs-6 ">% to be considered for saving</th>
                                <th class="border-bottom-0 w-5 fs-6 ">Maximum Savings Amount considered - Male </th>
                                <th class="border-bottom-0 w-5 fs-6 ">Maximum Savings Amount considered - Female</th>
                                <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                            </tr>
                        </thead>
                    <tbody style="max-height: 50vh; overflow: scroll;">
                           <tr *ngFor="let saveMaster of savingsmaster">
                                
                                <td>{{saveMaster.name}}</td>
                                <td>{{saveMaster.saving}}</td>
                                   <td>{{saveMaster.maxamountmale}}</td>
                                    <td>{{saveMaster.maxamountfemale}}</td>
                                <td>
                                    <button class="btn btn-primary btn-icon btn-sm me-2" data-method="edit" title="Edit"
                                        (click)="editSavingmaster(saveMaster)">
                                        <i class="fa fa-edit" data-bs-toggle="tooltip" data-original-title="edit"></i>
                                    </button>
                                    <button class="btn btn-danger btn-icon btn-sm me-2" data-method="delete"
                                        title="Delete" data-bs-target="#showAlertWarning"
                                        (click)="deleteSavingMaster(saveMaster.id)">
                                        <i class="fa fa-trash" data-bs-toggle="tooltip" data-original-title="edit"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody> 
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>