import { error } from "@angular/compiler/src/util";
import { Component, OnInit, ViewChild } from "@angular/core";
import { TileStyler } from "@angular/material/grid-list/tile-styler";
import { Configkey } from "../models/Configkey";
import { CommonService } from "../services/common.service";
import { CustomerService } from "../services/customer.service";
import { configSetting } from "../models/configSetting";
import { ApplicationSettingsService } from "../services/application-settings.service";
import { mandatory } from "../models/mandatory";
import { EmployeeMasterservice } from "../services/employeemaster.service";
import { DocumentInfoService } from "../services/documentinfo.service";
import { AdminConfig } from "../models/AdminConfig";
import { Level } from "../models/Level";
import { NgForm } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { AttendanceAuthority } from '../models/AttendanceAuthority';
import { Validators, AbstractControl, ValidationErrors, ValidatorFn, FormGroup } from '@angular/forms';
import { DocumentAuthority } from "../models/documentInfo";
import { MatGridTileHeaderCssMatStyler } from "@angular/material/grid-list";

@Component({
  selector: "app-setting",
  templateUrl: "./setting.component.html",
  styleUrls: ["./setting.component.css"],
})

export class SettingComponent implements OnInit {
  isFormValid: boolean = true;
  errorMessages: string[] = [];
  errorMessages1: string[] = [];
  errorMessages2: string[] = [];

  adminconfig_response: Configkey = new Configkey();
  fieldvalue: string = "Employeeid";
  isuserid: boolean = false;
  isemailvalid: boolean = false;
  ispresent: boolean = false;
  configList: configSetting = new configSetting();
  notificationSetting: string;
  swipeSetting: string = "firstIn_lOut";
  swipeSettingLeave: number;
  worknotification: number;
  deviceId: string;
  calenderDate: string;
  installationDate: string;
  joblevel: string = 'GTE';
  fromDate:string;
  toDate:string;
  pleave:number;
  dleave:number;
  extraTimeOut: number;
  currentDate: string;
  //remove if not working
  dept: any;
  selectedDes: any;
  selectedRoles: any;

  EncashmentFromDate : string;
  EncashmentToDate : string;

  // get  employee master table based on
  mandatoryFields: mandatory = new mandatory();
  changedFields: AdminConfig[] = []; // to save change done

  // approve

  level: Level = new Level();
  levelList: Level[] = [];

  approveLevel: number = 1;
  approvalLimit: number = 1;
  selectedLevel1: any;
  selectedLevel2: any;
  selectedLevel3: any;

  selectedDepartment1: any;
  selectedDepartment2: any;
  selectedDepartment3: any;

  designation: any;
  role: any;
  department: any;

  designation1: any;
  role1: any;
  department1: any;

  designation2: any;
  role2: any;
  department2: any;

  designation3: any;
  role3: any;
  department3: any;


  policyapproveLevel: number = 1;
  policyapprovallimit: number = 1;
  policyselectedLevel1: any;
  policyselectedLevel2: any;
  policyselectedLevel3: any;

  policyselectedDepartment1: any;
  policyselectedDepartment2: any;
  policyselectedDepartment3: any;

  policydesignation: any;
  policyrole: any;
  policydepartment: any;

  policydesignation1: any;
  policyrole1: any;
  policydepartment1: any;

  policydesignation2: any;
  policyrole2: any;
  policydepartment2: any;

  policydesignation3: any;
  policyrole3: any;
  policydepartment3: any;

  isDesignationValid1: boolean = true;
  designation2Touched1: boolean = false;

  isDesignationValid2: boolean = true;
  designation2Touched2: boolean = false;

  isPolicyDesignationValid1: boolean = true;
  policydesignation2Touched1: boolean = false;

  isPolicyDesignationValid2: boolean = true;
  policydesignation2Touched2: boolean = false;

  // attendance authority moducle //
  attendnaceApproveLevel: number = 1;
  documentApproveLevel: number = 1;
  attendanceAuthorities: AttendanceAuthority[] = [];
  documentAuthorities: DocumentAuthority[] = [];
  JobList: any;
  dpartmentList: any;
  attendanceAuth: any;

// leave encashment
startDate: boolean;
  endDate: boolean;
  submitted: boolean;

  //Salary Setting
  debitAccNum:any;

  constructor(
    private customerService: CustomerService,
    private applicationSettingsService: ApplicationSettingsService,
    private documentSettingsService: DocumentInfoService,
    private employeeMasterService: EmployeeMasterservice,
    private datePipe: DatePipe, private employeeMasterservice: EmployeeMasterservice
  ) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    // this.encashmentFromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    // this.encashmentToDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;


  }
  @ViewChild("policySubmissionform", { static: false })
  policySubmission: NgForm;

  ngOnInit(): void {

    let attAuth = new AttendanceAuthority();
    this.attendanceAuthorities.push(attAuth);
    this.documentAuthorities.push(attAuth);

    console.log(" INITIAL ");
    this.getdefaultemailid();
    this.getConfigTypeList();
    this.getMandatoryFields();
    this.getDesignation();
    this.getRole();
    this.getDepartment();
    this.getExpenseAuthority();
    this.getpolicyAuthority();
    this.getDepartmentForAttendanceAuthority();
    this.getJobList();
    this.getAttendanceAuthority();
    this.getDocumentAuthority();
    this.getDebitAccountNum();
    this.changedFields = [];
  }

  getdefaultemailid() {
    this.customerService.getdefault_emailid().subscribe(
      (res) => {
        console.log(res);
        if (res.default_email_id.trim().length === 0) {
          document.getElementById("emailid").setAttribute("checked", "checked");
          this.isuserid = false;
        } else if (res.default_email_id === "Employeeid") {
          document.getElementById("emailid").setAttribute("checked", "checked");
          this.isuserid = false;
        } else {
          document.getElementById("userid").setAttribute("checked", "checked");
          this.adminconfig_response.defaultemail = res.default_email_id;
          this.isuserid = true;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getConfigTypeList() {
    console.log("get method getConfigType");
    this.applicationSettingsService.getConfigType().subscribe(
      (res) => {
        console.log(
          "response of get method ..... from backend to fronend .. ",
          res
        );
        this.notificationSetting = res.configListRes[0];
        this.swipeSetting = res.configListRes[1];
        this.swipeSettingLeave = res.configListRes[2];
        this.worknotification = res.configListRes[3];
        this.calenderDate = res.configListRes[4];
        this.extraTimeOut = res.configListRes[6];
        console.log("this.extraTimeOut",this.extraTimeOut);
        const dateObject = new Date(res.configListRes[5]);
        const formattedDate = this.datePipe.transform(dateObject, "yyyy-MM-dd");
        this.installationDate = formattedDate;
        // this.joblevel = res.configListRes[6];
        // console.log(" job role ",this.joblevel);

        this.deviceId = res.configListRes[6];
        this.fromDate= formattedDate;
        this.toDate=formattedDate;
        this.EncashmentFromDate=res.configListRes[6];
        this.EncashmentToDate=res.configListRes[7];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getselectedfield(event) {
    this.fieldvalue = event.target.value;
    if (this.fieldvalue === "userID") {
      console.log("check  : userId");
      this.isuserid = true;
    } else if (this.fieldvalue === "Employeeid") {
      console.log("check  : empId");
      this.isuserid = false;
    }
  }

  getemailid_fromclient(event) {
    const regexpattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    this.adminconfig_response.defaultemail = event.target.value;
    if (regexpattern.exec(this.adminconfig_response.defaultemail)) {
      this.isemailvalid = false;
    }
    if (this.ispresent) {
      this.ispresent = false;
    }
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement("div");
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`;
    let alertcomp = document.querySelector(".alertcomp");
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  savechanges1() {
    console.log(
      "before save 1 insert ,adiminconfig_response : ",
      this.adminconfig_response
    );
    const regexpattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (this.fieldvalue === "userID") {
      if (
        this.adminconfig_response.defaultemail === undefined ||
        this.adminconfig_response.defaultemail.trim().length === 0
      ) {
        this.ispresent = true;
        return;
      } else if (!regexpattern.exec(this.adminconfig_response.defaultemail)) {
        this.isemailvalid = true;
        return;
      }
    } else {
      console.log("this.fieldvalue_response : ", this.fieldvalue);
      this.adminconfig_response.defaultemail = this.fieldvalue;
    }
    this.customerService
      .setdefault_emailid(this.adminconfig_response)
      .subscribe(
        (res) => {
          console.log(res);
          this.alertmessages("Successfully Saved ", "success");
        },
        (error) => {
          console.log(error);
          this.alertmessages("Please try again later", "danger");
        }
      );
  }

  savechanges2() {
    console.log("before save 2 insert ", this.configList);
    this.configList.worknotification =
      this.worknotification === 0 ? 4 : this.worknotification;
    this.configList.notificationSetting =
      this.notificationSetting === "" ? undefined : this.notificationSetting;
    this.applicationSettingsService.setConfigType(this.configList).subscribe(
      (res) => {
        console.log("response", res);
        this.alertmessages("Successfully Saved ", "success");
      },
      (error) => {
        console.log(error);
        this.alertmessages("Please try again later", "danger");
      }
    );
  }

  savechanges3() {
    console.log("before save 3 insert ", this.configList);
    this.configList.swipeSetting =
      this.swipeSetting === "" ? undefined : this.swipeSetting;
    this.configList.swipeSettingLeave =
      this.swipeSettingLeave === 0 ? undefined : this.swipeSettingLeave;
    this.configList.deviceId = this.deviceId === "" ? undefined : this.deviceId;
    this.configList.calenderDate =
      this.calenderDate === "" ? undefined : this.calenderDate;
    this.configList.installationDate =
      this.installationDate === "" ? undefined : this.installationDate;
    this.configList.joblevel =
      this.joblevel === "" ? undefined : this.joblevel;
      this.configList.extraTimeOut =
      this.extraTimeOut === 0 ? undefined : this.extraTimeOut;
    this.applicationSettingsService.setConfigType(this.configList).subscribe(
      (res) => {
        console.log("response", res);
        this.alertmessages("Successfully Saved ", "success");
      },
      (error) => {
        console.log(error);
        this.alertmessages("Please try again later", "danger");
      }
    );
  }

  // This will update the leavemaster data. // Based on LeaveType we should update the intervene holiday (Col Name: 'INTERVENEHOLS').
  // 0 or 1... It's better if we pass from client
  savechanges4() {
    console.log("before save 3 insert ", this.configList);
    this.configList.swipeSetting =
      this.swipeSetting === "" ? undefined : this.swipeSetting;
    this.configList.swipeSettingLeave =
      this.swipeSettingLeave === 0 ? undefined : this.swipeSettingLeave;
    this.applicationSettingsService.setConfigType(this.configList).subscribe(
      (res) => {
        console.log("response", res);
        this.alertmessages("Successfully Saved ", "success");
      },
      (error) => {
        console.log(error);
        this.alertmessages("Please try again later", "danger");
      }
    );
  }

  getMandatoryFields() {
    this.employeeMasterService.getMandatoryFieldForEM().subscribe({
      next: (data) => {
        this.mandatoryFields = data.mandatoryFieldEM;
        console.log("data here : ", this.mandatoryFields);
      },
      error: (e) => {
        console.log(e);
      },
    });
  }

  onInputChange(updatedValue: any, index: number) {
    const enteredValue = updatedValue.target.value;
    const cleanedValue = enteredValue.split(":")[1].trim();

    const updatedField = {
      configkey: this.mandatoryFields[index].configkey,
      configvalue: cleanedValue,
    };

    console.log("Updated Field: ", updatedField);
    this.changedFields.push(updatedField);
  }

  getDesignation() {
    this.applicationSettingsService.getDesignationType().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.designation = data.designationDetail;
        console.log("List of all the branch available :", this.designation);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getRole() {
    this.applicationSettingsService.getRoleType().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.role = data.roleDetail;
        console.log("List of all the branch available :", this.role);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getDepartment() {
    this.applicationSettingsService.getDepartmentType().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.department = data.departmentDetail;
        console.log("List of all the branch available :", this.department);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  savechanges5() {
    console.log("save button for update employeemaster fields");
    this.applicationSettingsService
      .setMadatoryFields(this.changedFields)
      .subscribe(
        (res) => {
          console.log("response", res);
          this.alertmessages("Successfully Saved ", "success");
        },
        (error) => {
          console.log(error);
          this.alertmessages("Please try again later", "danger");
        }
      );
    this.changedFields = [];
    console.log("empty : ", this.changedFields);
  }

  onDepartmentChange() {
    if (this.selectedDepartment1 === "OTH") {
      // Set department1 value to '1' or any desired value
      this.department1 = "1";
    }
    if (this.selectedDepartment2 === "OTH") {
      // Set department1 value to '1' or any desired value
      this.department2 = "1";
    }
    if (this.selectedDepartment3 === "OTH") {
      // Set department1 value to '1' or any desired value
      this.department3 = "1";
    }
  }

  onApprovelLevelDesChange() {
    if (this.selectedLevel1 === "D") {
      this.role1 = "3";
      this.checkDesignationValidation1();
      this.checkDesignationValidation2();
    } else if (this.selectedLevel1 === "R") {
      this.designation1 = 1;
      this.selectedDepartment1 = "DEP";
      this.checkDesignationValidation1();
      this.checkDesignationValidation2();
    }
    if (this.selectedLevel2 === "D") {
      this.role2 = "3";
      this.checkDesignationValidation2();
    } else if (this.selectedLevel2 === "R") {
      this.designation2 = "1";
      this.selectedDepartment2 = "DEP";
      this.checkDesignationValidation2();
    }
    if (this.selectedLevel3 === "D") {
      this.role3 = "3";
      this.checkDesignationValidation1();
      this.checkDesignationValidation2();
    } else if (this.selectedLevel3 === "R") {
      this.designation3 = "1";
      this.selectedDepartment3 = "DEP";
      this.checkDesignationValidation1();
      this.checkDesignationValidation2();
    }
  }

  getExpenseAuthority() {
    this.applicationSettingsService.getExpenseAuthority().subscribe(
      (data) => {
        console.log("Expense Authority List :", data);
        console.log("Expense Authority Item1:", data.expenseAuthority[0]);
        console.log("Expense Authority Item2:", data.expenseAuthority[1]);
        console.log("Expense Authority Item3:", data.expenseAuthority[2]);

        this.approveLevel = data.expenseAuthority[0].approvalLevel;
        this.approvalLimit = data.expenseAuthority[0].approvalLimit;

        if (
          data.expenseAuthority[0].designation != null ||
          data.expenseAuthority[0].designation != undefined
        ) {
          this.designation1 = data.expenseAuthority[0].designation;
        } else if (
          data.expenseAuthority[0].role == null ||
          data.expenseAuthority[0].role == undefined
        ) {
          this.designation1 = "1";
        }
        if (
          data.expenseAuthority[1].designation != null ||
          data.expenseAuthority[1].designation != undefined
        ) {
          this.designation2 = data.expenseAuthority[1].designation;
        } else if (
          data.expenseAuthority[1].role == null ||
          data.expenseAuthority[1].role == undefined
        ) {
          this.designation2 = "1";
        }
        if (
          data.expenseAuthority[2].designation != null ||
          data.expenseAuthority[2].designation != undefined
        ) {
          this.designation3 = data.expenseAuthority[2].designation;
        } else if (
          data.expenseAuthority[2].role == null ||
          data.expenseAuthority[2].role == undefined
        ) {
          this.designation3 = "1";
        }

        if (
          data.expenseAuthority[0].department != null ||
          data.expenseAuthority[0].department != undefined
        ) {
          this.department1 = data.expenseAuthority[0].department;
        } else if (
          data.expenseAuthority[0].role == null ||
          data.expenseAuthority[0].role == undefined
        ) {
          if (
            data.expenseAuthority[0].designation == null ||
            data.expenseAuthority[0].designation == undefined
          ) {
            this.department1 = null;
          } else {
            this.department1 = "1";
          }
        }
        if (
          data.expenseAuthority[1].department != null ||
          data.expenseAuthority[1].department != undefined
        ) {
          this.department2 = data.expenseAuthority[1].department;
        } else if (
          data.expenseAuthority[1].role == null ||
          data.expenseAuthority[1].role == undefined
        ) {
          if (
            data.expenseAuthority[1].designation == null ||
            data.expenseAuthority[1].designation == undefined
          ) {
            this.department2 = null;
          } else {
            this.department2 = "1";
          }
        }
        if (
          data.expenseAuthority[2].department != null ||
          data.expenseAuthority[2].department != undefined
        ) {
          this.department3 = data.expenseAuthority[2].department;
        } else if (
          data.expenseAuthority[2].role == null ||
          data.expenseAuthority[2].role == undefined
        ) {
          if (
            data.expenseAuthority[2].designation == null ||
            data.expenseAuthority[2].designation == undefined
          ) {
            this.department3 = null;
          } else {
            this.department3 = "1";
          }
        }

        if (
          data.expenseAuthority[0].role != null ||
          data.expenseAuthority[0].role != undefined
        ) {
          this.role1 = data.expenseAuthority[0].role;
        } else if (
          data.expenseAuthority[0].designation == null ||
          data.expenseAuthority[0].designation == undefined
        ) {
          this.role1 = "3";
        }
        if (
          data.expenseAuthority[1].role != null ||
          data.expenseAuthority[1].role != undefined
        ) {
          this.role2 = data.expenseAuthority[1].role;
        } else if (
          data.expenseAuthority[1].designation == null ||
          data.expenseAuthority[1].designation == undefined
        ) {
          this.role2 = "3";
        }
        if (
          data.expenseAuthority[2].role != null ||
          data.expenseAuthority[2].role != undefined
        ) {
          this.role3 = data.expenseAuthority[2].role;
        } else if (
          data.expenseAuthority[2].designation == null ||
          data.expenseAuthority[2].designation == undefined
        ) {
          this.role3 = "3";
        }

        if (this.role1 != null || this.role1 != undefined) {
          this.selectedLevel1 = "R";
        }
        console.log("role2", this.role2);
        if (this.role2 != null || this.role2 != undefined) {
          this.selectedLevel2 = "R";
        }
        if (this.role3 != null || this.role3 != undefined) {
          this.selectedLevel3 = "R";
        }

        this.selectedDepartment1 = null;
        this.selectedDepartment2 = null;
        this.selectedDepartment3 = null;
        if (
          this.designation1 != null ||
          this.designation1 != undefined ||
          this.department1 != null ||
          this.department1 != undefined
        ) {
          this.selectedLevel1 = "D";
          if (
            data.expenseAuthority[0].department != null &&
            data.expenseAuthority[0].department != undefined
          ) {
            this.selectedDepartment1 = "OTH";
          } else {
            this.selectedDepartment1 = "DEP";
          }
        }

        if (
          this.designation2 != null ||
          this.designation2 != undefined ||
          this.department2 != null ||
          this.department2 != undefined
        ) {
          this.selectedLevel2 = "D";
          if (
            data.expenseAuthority[1].department == null ||
            data.expenseAuthority[1].department == undefined
          ) {
            this.selectedDepartment2 = "DEP";
          } else if (
            data.expenseAuthority[1].department != null &&
            data.expenseAuthority[1].department != undefined
          ) {
            this.selectedDepartment2 = "OTH";
          } else {
            this.selectedDepartment2 = "DEP";
          }
        }
        if (
          this.designation3 != null ||
          this.designation3 != undefined ||
          this.department3 != null ||
          this.department3 != undefined
        ) {
          this.selectedLevel3 = "D";
          if (
            data.expenseAuthority[2].department == null ||
            data.expenseAuthority[2].department == undefined
          ) {
            this.selectedDepartment3 = "DEP";
          } else if (
            data.expenseAuthority[2].department != null &&
            data.expenseAuthority[2].department != undefined
          ) {
            this.selectedDepartment3 = "OTH";
          } else {
            this.selectedDepartment3 = "DEP";
          }
        }

        if (this.approveLevel == null || this.approveLevel == undefined) {
          this.approveLevel = 1;

          this.selectedLevel1 = "D";
          this.selectedDepartment1 = "DEP";
        }

        if (
          this.selectedDepartment1 == null ||
          this.selectedDepartment1 == undefined
        ) {
          this.selectedDepartment1 = "DEP";
        }
        if (
          this.selectedDepartment2 == null ||
          this.selectedDepartment2 == undefined
        ) {
          this.selectedDepartment2 = "DEP";
        }
        if (
          this.selectedDepartment3 == null ||
          this.selectedDepartment3 == undefined
        ) {
          this.selectedDepartment3 = "DEP";
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  saveExpenseAuthority() {
    this.level.selectedLevel1 = this.selectedLevel1;
    this.level.designation1 = this.designation1;
    this.level.role1 = this.role1;
    this.level.selectedDepartment1 = this.selectedDepartment1;
    this.level.department1 = this.department1;
    this.level.approvalLimit = this.approvalLimit;

    this.level.selectedLevel2 = this.selectedLevel2;
    this.level.designation2 = this.designation2;
    this.level.role2 = this.role2;
    this.level.selectedDepartment2 = this.selectedDepartment2;
    this.level.department2 = this.department2;

    this.level.selectedLevel3 = this.selectedLevel3;
    this.level.designation3 = this.designation3;
    this.level.role3 = this.role3;
    this.level.selectedDepartment3 = this.selectedDepartment3;
    this.level.department3 = this.department3;

    console.log("Selected Levels", this.approveLevel);
    if (this.approveLevel == 1) {
      this.level.designation2 = null;
      this.level.selectedDepartment2 = null;
      this.level.department2 = null;

      this.department2 = null;
      this.designation2 = null;
      this.selectedDepartment2 = null;

      this.level.designation3 = null;
      this.level.selectedDepartment3 = null;
      this.level.department3 = null;

      this.department3 = null;
      this.designation3 = null;
      this.selectedDepartment3 = null;

      this.level.role2 = null;
      this.level.role3 = null;

      this.role2 = null;
      this.role3 = null;
    }

    console.log("this.level.selectedLevel2", this.approveLevel);
    if (this.approveLevel == 2) {
      this.level.designation3 = null;
      this.level.selectedDepartment3 = null;
      this.level.department3 = null;

      this.department3 = null;
      this.designation3 = null;
      this.selectedDepartment3 = null;

      this.level.role3 = null;
      this.role3 = null;
    }

    if (this.level.selectedLevel1 === "D") {
      this.level.role1 = null;
      this.role1 = null;
    }
    if (this.level.selectedLevel1 === "R") {
      this.level.designation1 = null;
      this.level.selectedDepartment1 = null;
      this.level.department1 = null;

      this.department1 = null;
      this.designation1 = null;
      this.selectedDepartment1 = null;
    }
    if (this.level.selectedLevel2 === "D") {
      this.level.role2 = null;
      this.role2 = null;
    }
    if (this.level.selectedLevel2 === "R") {
      this.level.designation2 = null;
      this.level.selectedDepartment2 = null;
      this.level.department2 = null;

      this.department2 = null;
      this.designation2 = null;
      this.selectedDepartment2 = null;
    }

    if (this.level.selectedLevel3 === "D") {
      this.level.role3 = null;
      this.role3 = null;
    }

    if (this.level.selectedLevel3 === "R") {
      this.level.designation3 = null;
      this.level.selectedDepartment3 = null;
      this.level.department3 = null;

      this.department3 = null;
      this.designation3 = null;
      this.selectedDepartment3 = null;
    }

    if (this.selectedDepartment1 == "DEP") {
      this.department1 = null;
    }
    if (this.selectedDepartment2 == "DEP") {
      this.department2 = null;
    }
    if (this.selectedDepartment3 == "DEP") {
      this.department3 = null;
    }
    this.isFormValid = true;
    this.errorMessages1 = [];

    // Check if the required fields are selected
    if (!this.selectedLevel1) {
      this.errorMessages1.push("Please select Approval Levels");
      this.isFormValid = false;
    }
    this.errorMessages1 = [];
    this.isFormValid = true;

    if (this.approveLevel < 1 || this.approveLevel > 3) {
      this.errorMessages1.push("Please Enter a valid Expense Approval Levels");
      this.isFormValid = false;
    }

    if (this.approveLevel == 1) {
      this.validateApprovalLevel(1, this.selectedLevel1, this.designation1, this.role1, this.selectedDepartment1, this.department1);
    }

    if (this.approveLevel == 2) {
      this.validateApprovalLevel(1, this.selectedLevel1, this.designation1, this.role1, this.selectedDepartment1, this.department1);
      this.validateApprovalLevel(2, this.selectedLevel2, this.designation2, this.role2, this.selectedDepartment2, this.department2);
    }

    if (this.approveLevel === 3) {
      this.validateApprovalLevel(1, this.selectedLevel1, this.designation1, this.role1, this.selectedDepartment1, this.department1);
      this.validateApprovalLevel(2, this.selectedLevel2, this.designation2, this.role2, this.selectedDepartment2, this.department2);
      this.validateApprovalLevel(3, this.selectedLevel3, this.designation3, this.role3, this.selectedDepartment3, this.department3);
    }

    if (this.approveLevel > 4) {
      this.errorMessages1 = []
    }

    console.log(this.approveLevel);
    if (this.isFormValid) {
      this.level.orderlevels = 1;
      if (
        (this.designation1 != null && this.designation1 != undefined) ||
        (this.role1 != null && this.role1 != undefined) ||
        (this.department1 != null && this.department1 != undefined)
      ) {
        this.level.designation = this.designation1;
        this.level.department = this.department1;
        this.level.role = this.role1;
      }
      this.levelList.push(this.level);
      this.level = new Level();

      this.level.orderlevels = 2;
      if (
        (this.designation2 != null && this.designation2 != undefined) ||
        (this.role2 != null && this.role2 != undefined) ||
        (this.department2 != null && this.department2 != undefined)
      ) {
        this.level.designation = this.designation2;
        this.level.department = this.department2;
        this.level.role = this.role2;
      }
      this.levelList.push(this.level);
      this.level = new Level();

      this.level.orderlevels = 3;
      if (
        (this.designation3 != null && this.designation3 != undefined) ||
        (this.role3 != null && this.role3 != undefined) ||
        (this.department3 != null && this.department3 != undefined)
      ) {
        this.level.designation = this.designation3;
        this.level.department = this.department3;
        this.level.role = this.role3;
      }
      this.levelList.push(this.level);
      console.log("level list", this.levelList);
      this.applicationSettingsService
        .saveExpenseAuthority(this.levelList)
        .subscribe(
          (res) => {
            console.log("response", res);
            this.alertmessages("Successfully Saved ", "success");
          },
          (error) => {
            console.log(error);
            this.alertmessages("Please try again later", "danger");
          }
        );
      this.levelList = [];
      console.log("empty : ", this.changedFields);
    }
  }

  validateApprovalLevel(
    level: number,
    selectedLevel: string,
    designation: any,
    role: any,
    selectedDepartment: string,
    department: any
  ): void {
    if (selectedLevel === "D" && !designation) {
      this.errorMessages1.push(
        `Please select Designation for Approval Level ${level}.`
      );
      this.isFormValid = false;
    } else if (selectedLevel === "R" && !role) {
      this.errorMessages1.push(
        `Please select Role for Approval Level ${level}.`
      );
      this.isFormValid = false;
    }

    if (
      level >= 1 &&
      level <= 3 &&
      selectedDepartment === "OTH" &&
      !department
    ) {
      this.errorMessages1.push(
        `Please select Other Department for Approval Level ${level}.`
      );
      this.isFormValid = false;
    }

    if (this.isDesignationValid1 == false && this.designation2Touched1 == true && level == this.approveLevel) {
      this.errorMessages1.push(
        `Please choose the appropriate Approval level 2 designation`
      );
      this.isFormValid = false;
    }
    if (this.isDesignationValid2 == false && this.designation2Touched2 == true && level == this.approveLevel) {
      this.errorMessages1.push(
        `Please choose the appropriate Approval level 3 designation`
      );
      this.isFormValid = false;
    }
  }

  onDepartmentChange1() {
    if (this.policyselectedDepartment1 === "OTH") {
      // Set department1 value to '1' or any desired value
      this.policydepartment1 = "1";
    }
    if (this.policyselectedDepartment2 === "OTH") {
      // Set department1 value to '1' or any desired value
      this.policydepartment2 = "1";
    }
    if (this.policyselectedDepartment3 === "OTH") {
      // Set department1 value to '1' or any desired value
      this.policydepartment3 = "1";
    }
  }

  onApprovelLevelDesChange1() {
    if (this.policyselectedLevel1 === "D") {
      this.policyrole1 = "3";
      this.checkPolicyDesignationValidation1();
      this.checkPolicyDesignationValidation2();
    } else if (this.policyselectedLevel1 === "R") {
      this.policydesignation1 = 1;
      this.policyselectedDepartment1 = "DEP";
      this.checkPolicyDesignationValidation1();
      this.checkPolicyDesignationValidation2();
    }
    if (this.policyselectedLevel2 === "D") {
      this.policyrole2 = "3";
      this.checkPolicyDesignationValidation2();
    } else if (this.policyselectedLevel2 === "R") {
      this.policydesignation2 = "1";
      this.policyselectedDepartment2 = "DEP";
      this.checkPolicyDesignationValidation2();
    }
    if (this.policyselectedLevel3 === "D") {
      this.policyrole3 = "3";
      this.checkPolicyDesignationValidation1();
      this.checkPolicyDesignationValidation2();
    } else if (this.policyselectedLevel3 === "R") {
      this.policydesignation3 = "1";
      this.policyselectedDepartment3 = "DEP";
      this.checkPolicyDesignationValidation1();
      this.checkPolicyDesignationValidation2();
    }
  }

  getpolicyAuthority() {
    this.applicationSettingsService.getPolicyAuthority().subscribe(
      (data) => {
        console.log("Policy Authority List :", data);

        this.policyapproveLevel = data.policyAuthority[0].approvalLevel;
        this.policyapprovallimit = data.policyAuthority[0].policyapprovallimit;
        if (
          data.policyAuthority[0].designation != null ||
          data.policyAuthority[0].designation != undefined
        ) {
          this.policydesignation1 = data.policyAuthority[0].designation;
        } else if (
          data.policyAuthority[0].role == null ||
          data.policyAuthority[0].role == undefined
        ) {
          this.policydesignation1 = "1";
        }
        if (
          data.policyAuthority[1].designation != null ||
          data.policyAuthority[1].designation != undefined
        ) {
          this.policydesignation2 = data.policyAuthority[1].designation;
        } else if (
          data.policyAuthority[1].role == null ||
          data.policyAuthority[1].role == undefined
        ) {
          this.policydesignation2 = "1";
        }
        if (
          data.policyAuthority[2].designation != null ||
          data.policyAuthority[2].designation != undefined
        ) {
          this.policydesignation3 = data.policyAuthority[2].designation;
        } else if (
          data.policyAuthority[2].role == null ||
          data.policyAuthority[2].role == undefined
        ) {
          this.policydesignation3 = "1";
        }

        if (
          data.policyAuthority[0].department != null ||
          data.policyAuthority[0].department != undefined
        ) {
          this.policydepartment1 = data.policyAuthority[0].department;
        } else if (
          data.policyAuthority[0].role == null ||
          data.policyAuthority[0].role == undefined
        ) {
          this.policydepartment1 = "1";
        }
        if (
          data.policyAuthority[1].department != null ||
          data.policyAuthority[1].department != undefined
        ) {
          this.policydepartment2 = data.policyAuthority[1].department;
        } else if (
          data.policyAuthority[1].role == null ||
          data.policyAuthority[1].role == undefined
        ) {
          this.policydepartment2 = "1";
        }
        if (
          data.policyAuthority[2].department != null ||
          data.policyAuthority[2].department != undefined
        ) {
          this.policydepartment3 = data.policyAuthority[2].department;
        } else if (
          data.policyAuthority[2].role == null ||
          data.policyAuthority[2].role == undefined
        ) {
          this.policydepartment3 = "1";
        }

        if (
          data.policyAuthority[0].role != null ||
          data.policyAuthority[0].role != undefined
        ) {
          this.policyrole1 = data.policyAuthority[0].role;
        } else if (
          data.policyAuthority[0].designation == null ||
          data.policyAuthority[0].designation == undefined
        ) {
          this.policyrole1 = "3";
        }
        if (
          data.policyAuthority[1].role != null ||
          data.policyAuthority[1].role != undefined
        ) {
          this.policyrole2 = data.policyAuthority[1].role;
        } else if (
          data.policyAuthority[1].designation == null ||
          data.policyAuthority[1].designation == undefined
        ) {
          this.policyrole2 = "3";
        }
        if (
          data.policyAuthority[2].role != null ||
          data.policyAuthority[2].role != undefined
        ) {
          this.policyrole3 = data.policyAuthority[2].role;
        } else if (
          data.policyAuthority[2].designation == null ||
          data.policyAuthority[2].designation == undefined
        ) {
          this.policyrole3 = "3";
        }

        console.log("role1", this.policyrole1);
        if (this.policyrole1 != null || this.policyrole1 != undefined) {
          this.policyselectedLevel1 = "R";
        }
        console.log("role2", this.policyrole2);
        if (this.policyrole2 != null || this.policyrole2 != undefined) {
          this.policyselectedLevel2 = "R";
        }
        console.log("role3", this.policyrole3);
        if (this.policyrole3 != null || this.policyrole3 != undefined) {
          this.policyselectedLevel3 = "R";
        }

        this.policyselectedDepartment1 = null;
        this.policyselectedDepartment2 = null;
        this.policyselectedDepartment3 = null;

        if (
          this.policydesignation1 != null ||
          this.policydesignation1 != undefined ||
          this.policydepartment1 != null ||
          this.policydepartment1 != undefined
        ) {
          this.policyselectedLevel1 = "D";
          if (
            data.policyAuthority[0].department == null ||
            data.policyAuthority[0].department == undefined
          ) {
            this.policyselectedDepartment1 = "DEP";
          } else if (
            data.policyAuthority[0].department != null &&
            data.policyAuthority[0].department != undefined
          ) {
            this.policyselectedDepartment1 = "OTH";
          } else {
            this.policyselectedDepartment1 = "DEP";
          }
        }
        if (
          this.policydesignation2 != null ||
          this.policydesignation2 != undefined ||
          this.policydepartment2 != null ||
          this.policydepartment2 != undefined
        ) {
          this.policyselectedLevel2 = "D";
          if (
            data.policyAuthority[1].department == null ||
            data.policyAuthority[1].department == undefined
          ) {
            this.policyselectedDepartment2 = "DEP";
          } else if (
            data.policyAuthority[1].department != null &&
            data.policyAuthority[1].department != undefined
          ) {
            this.policyselectedDepartment2 = "OTH";
          } else {
            this.policyselectedDepartment2 = "DEP";
          }
        }
        if (
          this.policydesignation3 != null ||
          this.policydesignation3 != undefined ||
          this.policydepartment3 != null ||
          this.policydepartment3 != undefined
        ) {
          this.policyselectedLevel3 = "D";
          if (
            data.policyAuthority[2].department == null ||
            data.policyAuthority[2].department == undefined
          ) {
            this.policyselectedDepartment3 = "DEP";
          } else if (
            data.policyAuthority[2].department != null &&
            data.policyAuthority[2].department != undefined
          ) {
            this.policyselectedDepartment3 = "OTH";
          } else {
            this.policyselectedDepartment3 = "DEP";
          }
        }

        if (
          this.policyapproveLevel == null ||
          this.policyapproveLevel == undefined
        ) {
          this.policyapproveLevel = 1;

          this.policyselectedLevel1 = "D";
          this.policyselectedDepartment1 = "DEP";
        }

        if (
          this.policyselectedDepartment1 == null ||
          this.policyselectedDepartment1 == undefined
        ) {
          this.policyselectedDepartment1 = "DEP";
        }
        if (
          this.policyselectedDepartment2 == null ||
          this.policyselectedDepartment2 == undefined
        ) {
          this.policyselectedDepartment2 = "DEP";
        }
        if (
          this.policyselectedDepartment3 == null ||
          this.policyselectedDepartment3 == undefined
        ) {
          this.policyselectedDepartment2 = "DEP";
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  saveAuthority() {
    this.level.policyselectedLevel1 = this.policyselectedLevel1;
    this.level.policydesignation1 = this.policydesignation1;
    this.level.policyrole1 = this.policyrole1;
    this.level.policyselectedDepartment1 = this.policyselectedDepartment1;
    this.level.policydepartment1 = this.policydepartment1;
    this.level.policyapprovallimit = this.policyapprovallimit;

    console.log('Policy Approval Limit', this.policyapprovallimit)
    this.level.policyselectedLevel2 = this.policyselectedLevel2;
    this.level.policydesignation2 = this.policydesignation2;
    this.level.policyrole2 = this.policyrole2;
    this.level.policyselectedDepartment2 = this.policyselectedDepartment2;
    this.level.policydepartment2 = this.policydepartment2;

    this.level.policyselectedLevel3 = this.policyselectedLevel3;
    this.level.policydesignation3 = this.policydesignation3;
    this.level.policyrole3 = this.policyrole3;
    this.level.policyselectedDepartment3 = this.policyselectedDepartment3;
    this.level.policydepartment3 = this.policydepartment3;

    console.log("Selected Levels", this.policyapproveLevel);
    if (this.policyapproveLevel == 1) {
      this.level.policydesignation2 = null;
      this.level.policyselectedDepartment2 = null;
      this.level.policydepartment2 = null;

      this.policydepartment2 = null;
      this.policydesignation2 = null;
      this.policyselectedDepartment2 = null;

      this.level.policydesignation3 = null;
      this.level.policyselectedDepartment3 = null;
      this.level.policydepartment3 = null;

      this.policydepartment3 = null;
      this.policydesignation3 = null;
      this.policyselectedDepartment3 = null;

      this.level.policyrole2 = null;
      this.level.policyrole3 = null;

      this.policyrole2 = null;
      this.policyrole3 = null;
    }

    console.log("this.level.policyselectedLevel2", this.policyapproveLevel);
    if (this.policyapproveLevel == 2) {
      this.level.policydesignation3 = null;
      this.level.policyselectedDepartment3 = null;
      this.level.policydepartment3 = null;

      this.policydepartment3 = null;
      this.policydesignation3 = null;
      this.policyselectedDepartment3 = null;

      this.level.policyrole3 = null;
      this.policyrole3 = null;
    }

    if (this.level.policyselectedLevel1 === "D") {
      this.level.policyrole1 = null;
      this.policyrole1 = null;
    }
    if (this.level.policyselectedLevel1 === "R") {
      this.level.policydesignation1 = null;
      this.level.policyselectedDepartment1 = null;
      this.level.policydepartment1 = null;

      this.policydepartment1 = null;
      this.policydesignation1 = null;
      this.policyselectedDepartment1 = null;
    }
    if (this.level.policyselectedLevel2 === "D") {
      this.level.policyrole2 = null;
      this.policyrole2 = null;
    }
    if (this.level.policyselectedLevel2 === "R") {
      this.level.policydesignation2 = null;
      this.level.policyselectedDepartment2 = null;
      this.level.policydepartment2 = null;

      this.policydepartment2 = null;
      this.policydesignation2 = null;
      this.policyselectedDepartment2 = null;
    }

    if (this.level.policyselectedLevel3 === "D") {
      this.level.policyrole3 = null;
      this.policyrole3 = null;
    }

    if (this.level.policyselectedLevel3 === "R") {
      this.level.policydesignation3 = null;
      this.level.policyselectedDepartment3 = null;
      this.level.policydepartment3 = null;

      this.policydepartment3 = null;
      this.policydesignation3 = null;
      this.policyselectedDepartment3 = null;
    }

    if (this.policyselectedDepartment1 == "DEP") {
      this.policydepartment1 = null;
    }
    if (this.policyselectedDepartment2 == "DEP") {
      this.policydepartment2 = null;
    }
    if (this.policyselectedDepartment3 == "DEP") {
      this.policydepartment3 = null;
    }
    this.isFormValid = true;
    this.errorMessages = [];

    // Check if the required fields are selected
    if (!this.policyselectedLevel1) {
      this.errorMessages.push("Please select Approval Levels");
      this.isFormValid = false;
    }
    this.errorMessages = [];
    this.isFormValid = true;

    if (this.policyapproveLevel < 1 || this.policyapproveLevel > 3) {
      this.errorMessages.push("Please Enter a valid Expense Approval Levels");
      this.isFormValid = false;
    }

    if (this.policyapproveLevel == 1) {
      this.validateApprovalLevel1(
        1,
        this.policyselectedLevel1,
        this.policydesignation1,
        this.policyrole1,
        this.policyselectedDepartment1,
        this.policydepartment1
      );
    }

    if (this.policyapproveLevel == 2) {
      this.validateApprovalLevel1(
        1,
        this.policyselectedLevel1,
        this.policydesignation1,
        this.policyrole1,
        this.policyselectedDepartment1,
        this.policydepartment1
      );
      this.validateApprovalLevel1(
        2,
        this.policyselectedLevel2,
        this.policydesignation2,
        this.policyrole2,
        this.selectedDepartment2,
        this.policydepartment2
      );
    }

    if (this.policyapproveLevel === 3) {
      this.validateApprovalLevel1(
        1,
        this.policyselectedLevel1,
        this.policydesignation1,
        this.policyrole1,
        this.policyselectedDepartment1,
        this.policydepartment1
      );
      this.validateApprovalLevel1(
        2,
        this.policyselectedLevel2,
        this.policydesignation2,
        this.policyrole2,
        this.policyselectedDepartment2,
        this.policydepartment2
      );
      this.validateApprovalLevel1(
        3,
        this.policyselectedLevel3,
        this.policydesignation3,
        this.policyrole3,
        this.policyselectedDepartment3,
        this.policydepartment3
      );
    }

    if (this.policyapproveLevel > 4) {
      this.errorMessages1 = [];
    }

    console.log(this.approveLevel);
    if (this.isFormValid) {
      this.level.orderlevels = 1;
      if (
        (this.policydesignation1 != null &&
          this.policydesignation1 != undefined) ||
        (this.policyrole1 != null && this.policyrole1 != undefined) ||
        (this.policydepartment1 != null && this.policydepartment1 != undefined)
      ) {
        this.level.designation = this.policydesignation1;
        this.level.department = this.policydepartment1;
        this.level.role = this.policyrole1;
      }
      this.levelList.push(this.level);
      this.level = new Level();

      this.level.orderlevels = 2;
      if (
        (this.policydesignation2 != null &&
          this.policydesignation2 != undefined) ||
        (this.policyrole2 != null && this.policyrole2 != undefined) ||
        (this.policydepartment2 != null && this.policydepartment2 != undefined)
      ) {
        this.level.designation = this.policydesignation2;
        this.level.department = this.policydepartment2;
        this.level.role = this.policyrole2;
      }
      this.levelList.push(this.level);
      this.level = new Level();

      this.level.orderlevels = 3;
      if (
        (this.policydesignation3 != null &&
          this.policydesignation3 != undefined) ||
        (this.policyrole3 != null && this.policyrole3 != undefined) ||
        (this.policydepartment3 != null && this.policydepartment3 != undefined)
      ) {
        this.level.designation = this.policydesignation3;
        this.level.department = this.policydepartment3;
        this.level.role = this.policyrole3;
      }
      this.levelList.push(this.level);
      console.log("level list", this.levelList);
      this.applicationSettingsService
        .savePolicyAuthority(this.levelList)
        .subscribe(
          (res) => {
            console.log("response", res);
            this.alertmessages("Successfully Saved ", "success");
          },
          (error) => {
            console.log(error);
            this.alertmessages("Please try again later", "danger");
          }
        );
      this.levelList = [];
      console.log("empty : ", this.changedFields);
    }
  }

  validateApprovalLevel1(
    level: number,
    policyselectedLevel: string,
    designation: any,
    role: any,
    policyselectedDepartment: string,
    department: any
  ): void {
    if (policyselectedLevel === "D" && !designation) {
      this.errorMessages.push(
        `Please select Designation for Approval Level ${level}.`
      );
      this.isFormValid = false;
    } else if (policyselectedLevel === "R" && !role) {
      this.errorMessages.push(
        `Please select Role for Approval Level ${level}.`
      );
      this.isFormValid = false;
    }

    if (
      level >= 1 &&
      level <= 3 &&
      policyselectedDepartment === "OTH" &&
      !department
    ) {
      this.errorMessages.push(
        `Please select Other Department for Approval Level ${level}.`
      );
      this.isFormValid = false;
    }
    if (this.isPolicyDesignationValid1 == false && this.policydesignation2Touched1 == true && level == this.policyapproveLevel) {
      this.errorMessages.push(
        `Please choose the appropriate Approval level 2 designation`
      );
      this.isFormValid = false;
    }
    if (this.isPolicyDesignationValid2 == false && this.policydesignation2Touched2 == true && level == this.policyapproveLevel) {
      this.errorMessages.push(
        `Please choose the appropriate Approval level 3 designation`
      );
      this.isFormValid = false;
    }
  }

  checkDesignationValidation1(): void {
    // Check the condition
    console.log("validation", this.selectedLevel1)
    if (this.selectedLevel1 != "R") {
      console.log("validation", this.designation2, this.designation1)
      this.isDesignationValid1 = this.designation2 < this.designation1;
      console.log("validation", this.isDesignationValid1)
      this.designation2Touched1 = true;
    } else {
      this.isDesignationValid1 = true;
      this.designation2Touched1 = true;
    }
    if (this.selectedLevel2 != "R") {
      this.isDesignationValid2 = this.designation3 < this.designation2;
      this.designation2Touched2 = true;
    }
  }

  checkDesignationValidation2(): void {
    // Check the condition
    console.log("validation", this.selectedLevel2)
    if (this.selectedLevel2 != "R") {
      this.isDesignationValid2 = this.designation3 < this.designation2;
      this.designation2Touched2 = true;
    } else {
      this.isDesignationValid2 = true;
      this.designation2Touched2 = true;
    }
  }

  checkPolicyDesignationValidation1(): void {
    // Check the condition
    console.log("validation", this.policyselectedLevel1)
    console.log("validation", this.policydesignation2)
    console.log("validation", this.policydesignation1)
    if (this.policyselectedLevel1 != "R") {
      this.isPolicyDesignationValid1 = this.policydesignation2 < this.policydesignation1;
      this.policydesignation2Touched1 = true;
    } else {
      this.isPolicyDesignationValid1 = true;
      this.policydesignation2Touched1 = true;
    }
    if (this.policyselectedLevel2 != "R") {
      this.isPolicyDesignationValid2 = this.designation3 < this.designation2;
      this.policydesignation2Touched2 = true;
    }
    console.log("isPolicyDesignationValid1", this.isPolicyDesignationValid1);
  }

  checkPolicyDesignationValidation2(): void {
    // Check the condition
    console.log("validation", this.policyselectedLevel2)
    if (this.policyselectedLevel2 != "R") {
      this.isPolicyDesignationValid2 = this.policydesignation3 < this.policydesignation2;
      this.policydesignation2Touched2 = true;
    } else {
      this.isPolicyDesignationValid2 = true;
      this.policydesignation2Touched2 = true;
    }
  }

  checkApprovalLevelDesignationValidation(): void {
    // Check the condition
    if (this.approveLevel == 1) {
      this.checkDesignationValidation1();
      this.checkDesignationValidation2();
      if (this.designation2 == null || this.designation2 == undefined) {
        this.designation2 = "1";
        this.selectedDepartment2 = "DEP"
      }
      if (this.designation3 == null || this.designation3 == undefined) {
        this.designation3 = "1";
        this.selectedDepartment3 = "DEP"
      }
      this.isDesignationValid1 = true;
      this.designation2Touched1 = true;
      this.isDesignationValid2 = true;
      this.designation2Touched2 = true;
    }
    if (this.approveLevel == 2) {
      if (this.designation2 == null || this.designation2 == undefined) {
        this.designation2 = "1";
        this.selectedDepartment2 = "DEP"
      }
      if (this.designation3 == null || this.designation3 == undefined) {
        this.designation3 = "1";
        this.selectedDepartment3 = "DEP"
      }
      this.checkDesignationValidation1();
      this.checkDesignationValidation2();
      if (this.designation2 == null || this.designation2 == undefined) {
        this.designation2 = "1";
        this.selectedDepartment2 = "DEP"
      }
      if (this.designation3 == null || this.designation3 == undefined) {
        this.designation3 = "1";
        this.selectedDepartment3 = "DEP"
      }
      this.isDesignationValid2 = true;
      this.designation2Touched2 = true;
    }
    if (this.approveLevel == 3) {
      this.checkDesignationValidation1();
      this.checkDesignationValidation2();
      if (this.designation2 == null || this.designation2 == undefined) {
        this.designation2 = "1";
        this.selectedDepartment2 = "DEP"
      }
      if (this.designation3 == null || this.designation3 == undefined) {
        this.designation3 = "1";
        this.selectedDepartment3 = "DEP"
      }
    }
  }

  checkPolicyApprovalLevelDesignationValidation(): void {
    // Check the condition
    if (this.policyapproveLevel == 1) {
      this.checkPolicyDesignationValidation1();
      this.checkPolicyDesignationValidation2();
      if (this.policydesignation2 == null || this.policydesignation2 == undefined) {
        this.policydesignation2 = "1";
        this.policyselectedDepartment2 = "DEP"
      }
      if (this.policydesignation3 == null || this.policydesignation3 == undefined) {
        this.policydesignation3 = "1";
        this.policyselectedDepartment3 = "DEP"
      }
      this.isPolicyDesignationValid1 = true;
      this.policydesignation2Touched1 = true;

      this.isPolicyDesignationValid2 = true;
      this.policydesignation2Touched2 = true;
    }
    if (this.policyapproveLevel == 2) {
      if (this.policydesignation2 == null || this.policydesignation2 == undefined) {
        this.policydesignation2 = "1";
        this.policyselectedDepartment2 = "DEP"
      }
      if (this.policydesignation3 == null || this.policydesignation3 == undefined) {
        this.policydesignation3 = "1";
        this.policyselectedDepartment3 = "DEP"
      }
      this.checkPolicyDesignationValidation1();
      this.checkPolicyDesignationValidation2();

      if (this.policydesignation2 == null || this.policydesignation2 == undefined) {
        this.policydesignation2 = "1";
        this.policyselectedDepartment2 = "DEP"
      }
      if (this.policydesignation3 == null || this.policydesignation3 == undefined) {
        this.policydesignation3 = "1";
        this.policyselectedDepartment3 = "DEP"
      }
      this.isPolicyDesignationValid2 = true;
      this.policydesignation2Touched2 = true;
    }
    if (this.policyapproveLevel == 3) {
      this.checkPolicyDesignationValidation1();
      this.checkPolicyDesignationValidation2();
      if (this.policydesignation2 == null || this.policydesignation2 == undefined) {
        this.policydesignation2 = "1";
        this.policyselectedDepartment2 = "DEP"
      }
      if (this.policydesignation3 == null || this.policydesignation3 == undefined) {
        this.policydesignation3 = "1";
        this.policyselectedDepartment3 = "DEP"
      }
    }
  }


  // ------------------------------------------------------ // 
  // attendace authority module // 

  attAuthorityValidation(): boolean {
    console.log("leaveAuthorityValidation ");
    const hasUndefinedJobRole = this.attendanceAuthorities.some(item => item.jobRole === undefined);
    return hasUndefinedJobRole;
  }
  docAuthorityValidation(): boolean {
    console.log("documentAuthorityValidation ");
    const hasUndefinedJobRole = this.documentAuthorities.some(item => item.jobRole === undefined);
    return hasUndefinedJobRole;
  }


  attendanceAuthorityValidation() {
    console.log("inside authority validation ");
    debugger
    let ele1, ele2, ele3;

    console.log("leaveAuthorities ", this.attendanceAuthorities);


    this.attendanceAuthorities.forEach((item, index) => {
      if (index === 0) {
        ele1 = item.jobRole;
      } else if (index === 1) {
        ele2 = item.jobRole;
      } else if (index === 2) {
        ele3 = item.jobRole;
      }
    });
    console.log("value of ele1  ", ele1, "ele2  ", ele2, "ele3  ", ele3);


    // Comparison logic
    if (ele1 !== undefined && ele2 !== undefined && ele3 !== undefined) {
      console.log("3 level approval ");

      if (ele2 > ele1) {
        console.log("JobRole is higher than Approval level 2");
        this.updateLabel('JobRole1', "JobRole is higher than Approval level 1");
        return true;
      }

      if (ele2 == ele1) {
        console.log("JobRole is equal to Approval level 2");
        this.updateLabel('JobRole1', "JobRole is equal to Approval level 1");
        return true;
      }

      if (ele3 > ele2) {
        console.log("JobRole is higher than Approval level 2");
        this.updateLabel('JobRole2', "JobRole is higher than Approval level 2");
        return true;
      }

      if (ele3 == ele2) {
        console.log("JobRole is equal to Approval level 2");
        this.updateLabel('JobRole2', "JobRole is equal to Approval level 2");
        return true;
      }
      if (ele1 > ele2 && ele2 > ele3) {

        console.log("proper ele1  ", ele1, "ele2  ", ele2, "ele3  ", ele3);

        this.hideLabel('JobRole0');
        this.hideLabel('JobRole1');
        this.hideLabel('JobRole2');
      }

    } else if (ele1 !== undefined && ele2 !== undefined) {
      console.log("two level approval ");

      if (ele2 > ele1) {
        console.log("JobRole is higher than Approval level 1");
        this.updateLabel('JobRole1', "JobRole is higher than Approval level 1");
        return true;
      }

      if (ele2 == ele1) {
        console.log("JobRole is equal to Approval level 1");
        this.updateLabel('JobRole1', "JobRole is equal to Approval level 1");
        return true;
      }
      if (ele1 > ele2) {
        this.hideLabel('JobRole1');
      }
    }
  }

  documentAuthorityValidation() {
    console.log("inside authority validation ");
    debugger
    let ele1, ele2, ele3;

    console.log("leaveAuthorities ", this.documentAuthorities);


    this.documentAuthorities.forEach((item, index) => {
      if (index === 0) {
        ele1 = item.jobRole;
      } else if (index === 1) {
        ele2 = item.jobRole;
      } else if (index === 2) {
        ele3 = item.jobRole;
      }
    });
    console.log("value of ele1  ", ele1, "ele2  ", ele2, "ele3  ", ele3);


    // Comparison logic
    if (ele1 !== undefined && ele2 !== undefined && ele3 !== undefined) {
      console.log("3 level approval ");

      if (ele2 > ele1) {
        console.log("JobRole is higher than Approval level 2");
        this.updateLabel('JobRole1', "JobRole is higher than Approval level 1");
        return true;
      }

      if (ele2 == ele1) {
        console.log("JobRole is equal to Approval level 2");
        this.updateLabel('JobRole1', "JobRole is equal to Approval level 1");
        return true;
      }

      if (ele3 > ele2) {
        console.log("JobRole is higher than Approval level 2");
        this.updateLabel('JobRole2', "JobRole is higher than Approval level 2");
        return true;
      }

      if (ele3 == ele2) {
        console.log("JobRole is equal to Approval level 2");
        this.updateLabel('JobRole2', "JobRole is equal to Approval level 2");
        return true;
      }
      if (ele1 > ele2 && ele2 > ele3) {

        console.log("proper ele1  ", ele1, "ele2  ", ele2, "ele3  ", ele3);

        this.hideLabel('JobRole0');
        this.hideLabel('JobRole1');
        this.hideLabel('JobRole2');
      }

    } else if (ele1 !== undefined && ele2 !== undefined) {
      console.log("two level approval ");

      if (ele2 > ele1) {
        console.log("JobRole is higher than Approval level 1");
        this.updateLabel('JobRole1', "JobRole is higher than Approval level 1");
        return true;
      }

      if (ele2 == ele1) {
        console.log("JobRole is equal to Approval level 1");
        this.updateLabel('JobRole1', "JobRole is equal to Approval level 1");
        return true;
      }
      if (ele1 > ele2) {
        this.hideLabel('JobRole1');
      }
    }
  }

  updateLabel(jobRoleName: string, message: string) {
    console.log("update Label ", jobRoleName);
    const label = document.getElementById(`${jobRoleName}-label`);
    if (label) {
      label.textContent = message;
      label.style.display = 'block'; // Ensure the label is displayed
    }
  }

  hideLabel(jobRoleName: string) {
    const label = document.getElementById(`${jobRoleName}-label`);
    if (label) {
      label.style.display = 'none'; // Hide the label
    }
  }


  checkApprovalLevel() {
    console.log("check appoval level ");
    console.log("leave before ", this.attendanceAuthorities)

    if (this.attendnaceApproveLevel > this.attendanceAuthorities.length) {
      console.log("add ");
      let obj = new AttendanceAuthority();

      this.attendanceAuthorities.push(obj);
      // this.leaveAuthorities.reverse();

      console.log("leave ", this.attendanceAuthorities)
      console.log("leave ", this.attendanceAuthorities.length)

    } else {


      // this.LeaveTypeList.reverse();
      this.attendanceAuthorities.pop();
      // this.LeaveTypeList.reverse();

      console.log("leave ", this.attendanceAuthorities)
      console.log("leave ", this.attendanceAuthorities.length)
    }
    console.log("leave after ", this.attendanceAuthorities)

  }

  checkDocumentApprovalLevel() {
    console.log("check appoval level ");
    console.log("leave before ", this.documentAuthorities)

    if (this.documentApproveLevel > this.documentAuthorities.length) {
      console.log("add ");
      let obj = new DocumentAuthority();

      this.documentAuthorities.push(obj);
      // this.leaveAuthorities.reverse();

      console.log("leave ", this.documentAuthorities)
      console.log("leave ", this.documentAuthorities.length)

    } else {


      // this.LeaveTypeList.reverse();
      this.documentAuthorities.pop();
      // this.LeaveTypeList.reverse();

      console.log("leave ", this.documentAuthorities)
      console.log("leave ", this.documentAuthorities.length)
    }
    console.log("leave after ", this.documentAuthorities)

  }

  saveLeaveAndAttenadanceAuthority() {
    console.log("saveLeaveAndAttenadanceAuthority ........");

    if (this.attAuthorityValidation()) {
      console.log("there is undefined job role in list ");
      this.alertmessages("Please Fill all Job Role ", "danger");
      return;
    }
    if (this.attendanceAuthorityValidation()) {
      return;
    }


    this.applicationSettingsService
      .saveAttendanceAuthority(this.attendanceAuthorities)
      .subscribe(
        (res) => {
          console.log("response", res);
          this.alertmessages("Successfully Saved ", "success");
        },
        (error) => {
          console.log(error);
          this.alertmessages("Please try again later", "danger");
        }
      );
  }

  saveDocumentAuthority() {
    console.log("saveDocumentAuthority ........");

    if (this.docAuthorityValidation()) {
      console.log("there is undefined job role in list ");
      this.alertmessages("Please Fill all Job Role ", "danger");
      return;
    }
    if (this.documentAuthorityValidation()) {
      return;
    }


    this.documentSettingsService
      .saveDocumentAuthority(this.documentAuthorities)
      .subscribe(
        (res) => {
          console.log("response", res);
          this.alertmessages("Successfully Saved ", "success");
        },
        (error) => {
          console.log(error);
          this.alertmessages("Please try again later", "danger");
        }
      );
  }

  getJobList() {
    console.warn("getJobList ");
    this.employeeMasterservice.getJobType().subscribe(
      data => {
        this.JobList = data;
        console.log(this.JobList);

      }, error => {
        console.log(error);
      }
    );
  }

  getDepartmentForAttendanceAuthority() {
    this.applicationSettingsService.getDepartmentType().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.dpartmentList = data.departmentDetail;
        console.log("List of all the branch available :", this.dpartmentList);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  checkFromAndToDate() {
    this.startDate = false;
    this.endDate = false;
    this.submitted = true;
    const currentDate = new Date();

    if (this.EncashmentToDate) {
      if (this.EncashmentFromDate > this.EncashmentToDate) {
        this.submitted = false
        this.endDate = true;
      } else {
        this.endDate = false;
        return true;
      }
    }
  }

  saveLeaveEncashment(){

    if(this.EncashmentFromDate ==  ""){
      this.alertmessages("Please enter FromDate","danger");
      return;
    }

    if(this.EncashmentToDate == ""){
      this.alertmessages("Please enter ToDate","danger");
      return;
    }
    
    this.applicationSettingsService.saveEncashment(this.EncashmentFromDate,this.EncashmentToDate ).subscribe(
    // this.applicationSettingsService.setConfigType(this.configList).subscribe(
      (data) => {
        console.log("Data available :", data);
        this.alertmessages("successfully updated","success");
       
      },
      (error) => {
        console.log(error);
      }
    );
    
  }
  getAttendanceAuthority() {
    console.warn("getJobList ");
    this.employeeMasterservice.getAttAuth().subscribe(
      data => {

        this.attendnaceApproveLevel = data.attendanceAuthorityList.length;
        this.attendanceAuthorities = data.attendanceAuthorityList;

      }, error => {
        console.log(error);
      }
    );
  }

  getDocumentAuthority() {
    console.warn("getJobList ");
    this.employeeMasterservice.getDocAuth().subscribe(
      data => {

        this.documentApproveLevel = data.attendanceAuthorityList.length;
        this.documentAuthorities = data.attendanceAuthorityList;

      }, error => {
        console.log(error);
      }
    );
  }

  getDebitAccountNum() {
    console.warn("getJobList ");
    this.applicationSettingsService.getDebitAccNum().subscribe(
      data => {
        console.log(data);
        this.debitAccNum = data.debAccNum;

      }, error => {
        console.log(error);
      }
    );
  }

  savechanges11() {
    
    this.applicationSettingsService.UpdateOrInsertDebitAccNum(this.debitAccNum).subscribe(
      (res) => {
        console.log("response", res);
        this.alertmessages("Successfully Saved ", "success");
      },
      (error) => {
        console.log(error);
        this.alertmessages("Please try again later", "danger");
      }
    );
  }

  onClear(){
    this.EncashmentFromDate ='';
    this.EncashmentToDate = '';
    
  }

  

}
