<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <div class="card-body px-0">
            <div class="page-leftheader my-3">
                <div class="page-title">IT Slab Master</div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body row">
                        <div class="col-md-3 col-sm-3 col-xs-3">


                            <label class="form-label">Lower Limit<span style="color: rgb(248, 111, 111);">
                                    &nbsp;*</span></label>
                            <input type="text" class="form-control "  placeholder="Enter Salary Lower limit" [(ngModel)]="insertITSlabDetails.lowerlimit">
                        </div>
                        <div class="col-md-3 col-sm-4 col-xs-3">

                            <label class="form-label">Upper Limit<span style="color: rgb(248, 111, 111);">
                                    &nbsp;*</span></label>
                            <input type="text" class="form-control " placeholder="Enter Salary Upper limit"  [(ngModel)]="insertITSlabDetails.upperlimit">
                        </div>

                        <div
                        class="col-md-6 col-sm-6 col-xs-6 justify-content-between align-items-start">
                        
                        <div class="form-group label-floating">
                            <label class="form-label">&nbsp;</label>
                            <button *ngIf="addOrUpdate; else updateButton" type="button"
                                class="btn btn-primary w-40" id="navigatetarget"
                                style="margin-right: 3vw;" (click)="addMaster()">
                                Add
                            </button>
                            <ng-template #updateButton>
                                <button type="button" id="navigatetarget"
                                    class="btn btn-primary w-40" style="margin-right: 3vw;"
                                    (click)="updateMaster(insertITSlabDetails)">
                                    Update
                                </button>
                            </ng-template>
                            <button type="button" class="btn btn-danger w-40"
                                (click)="viewMaster()">
                                View
                            </button>
                        </div>
                    </div>

                    </div>
                </div>
            </div>
        </div>

        <!--IT Master onclick view enable-->
        <div class="card col-md-12 col-sm-12 col-xs-12" *ngIf="add_view_toggle == 'viewMaster' ">
            <div class="card-header border-bottom-0">
                <h3 class="card-title">IT Slab Master Details</h3>
            </div>
            <div class="card-body card1S p-0">
                <div class="table-responsive">
                    <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                        id="hr-table">
                        <thead class="tablerow">
                            <tr class="rowcolors stickyatTop">
                                <th class="border-bottom-0 w-5 fs-6 ">Serial No </th>
                                <th class="border-bottom-0 w-5 fs-6 ">Lower Limit </th>
                                <th class="border-bottom-0 w-5 fs-6 ">Upper Limit</th>
                                <th class="border-bottom-0 w-5 fs-6 ">Edu Cess % </th>
                                <!-- <th class="border-bottom-0 w-5 fs-6 ">MALE Applicable </th>
                                <th class="border-bottom-0 w-5 fs-6 ">FEMALE Applicable</th> -->
                                <th class="border-bottom-0 w-5 fs-6 ">Action</th>

                            </tr>
                        </thead>

                        <tbody style="max-height: 50vh; overflow: scroll;">
                            <tr *ngFor="let rs of ITSlabMasterData;let i = index">
                                <ng-container *ngIf="rs.ruleId!=0">
                                    <td>{{i+1}}</td>
                                    <td>{{rs.lowerlimit}}</td>
                                    <td>{{rs.upperlimit}}</td>
                                    <td>{{rs.eduCessPercent}}</td>
                                    <!-- <td>{{rs.maleApplicable}}</td>
                                    <td>{{rs.femaleApplicable}}</td> -->
                                    <td>
                                        <button class="btn btn-primary btn-icon btn-sm me-2" data-method="edit"
                                            title="Edit" (click)="editmaster(rs.id)">
                                            <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                data-original-title="edit"></i>
                                        </button>
                                        <button class="btn btn-danger btn-icon btn-sm me-2" data-method="delete"
                                            title="Delete" (click)="onDeleteHandler(rs)"
                                            data-bs-target="#showAlertWarning">
                                            <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                data-original-title="delete"></i>
                                        </button>
                                    </td>
                                </ng-container>
                            </tr>
                       

                        </tbody>

                    </table>
                </div>
            </div>
        </div>



        <!---Panel for Master Data-->

        <div class="row">
            <div class="col-md-12">
                <div class="card" *ngIf="add_view_toggle == 'addMaster' ">
                    <div class="card-body">
                        <div class="panel-group1" id="accordion2">
                            <div class="panel panel-default mb-4 overflow-hidden br-7">
                                <div class="panel-heading1">
                                    <h4 class="panel-title1">
                                        <a class="accordion-toggle collapsed  " data-bs-toggle="collapse"
                                            data-parent="#accordion" href="#collapseTwo" aria-expanded="false">IT Master
                                            Data</a>
                                    </h4>
                                </div>
                                <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel"
                                    aria-expanded="false">
                                    <div class="panel-body m-0 p-0">
                                        <div class="card-body">
                                            <div class=" row col-md-12 col-sm-12 col-xs-12 d-flex">
                                                <div class="col-md-6 col-sm-6 col-xs-6">
                                                    <div class="form-group label-floating">
                                                        <label class="form-label">Lower Limit<span>*</span></label>
                                                        <div>
                                                            <input type="text" name="lowerLimit" [(ngModel)]="insertITSlabDetails.lowerlimit"
                                                                class="form-control col-md-12 col-xs-12"
                                                                autocomplete="off" placeholder="Enter Salary Lower Limit"
                                                                autofocus="true" />
                                                        </div>
                                                        <!-- <span class="help-text text-danger">Enter
                                                            Lower Limit
                                                        </span> -->
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-6">
                                                <div class="form-group label-floating">
                                                    <label class="form-label">Upper Limit<span>*</span></label>
                                                    <div>
                                                        <input type="text" name="upperLimit"  [(ngModel)]="insertITSlabDetails.upperlimit"
                                                            class="form-control col-md-12 col-xs-12" autocomplete="off"
                                                            placeholder="Enter Salary Upper Limit" autofocus="true" />
                                                    </div>
                                                    <!-- <span class="help-text text-danger">Enter
                                                        Upper Limit
                                                    </span> -->
                                                </div>
                                               </div>
                                            <!-- <div class="clearfix"> </div> -->
                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Edu Cess %<span>*</span></label>
                                                <div>
                                                    <input type="text" name="eduCess" [(ngModel)]="insertITSlabDetails.eduCessPercent"
                                                        class="form-control col-md-12 col-xs-12" autocomplete="off"
                                                        placeholder="Enter Percentage" autofocus="true" />
                                                </div>
                                                <!-- <span class="help-text text-danger">Enter
                                                    EDU Cess
                                                </span> -->
                                            </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                                <div class="form-group label-floating">
                                                    <label class="form-label">Select Regime<span>*</span></label>
                                                    <div class="col-md-12 col-sm-12 col-xs-12 m-0 p-0">
                                                    <div class="form-group label-floating">
                                                        <div class="w-100">
                                                        <select class="form-control col-md-12 col-xs-12"
                                                            style="height: 38px;"
                                                            data-style="btn btn-drpdwn btn-round"
                                                            [(ngModel)]="insertITSlabDetails.selectRegimeId"
                                                            id="SelectRegime"
                                                            name="SelectRegime">
                                                            <option value=""> </option>
                                                            <option value=0> New Regime </option>
                                                            <option value=1> Old Regime </option>
                                                        </select>

                                                        </div>
                                                    </div>
                                                </div>

                                                </div>
                                                </div>

                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Applicable For<span>*</span></label>
                                                <div>
                                                    <input type="checkbox" name="gender" [(ngModel)]="insertITSlabDetails.femaleApplicable"
                                                       > Female
                                                    <input  style="margin-left: 2vw;" type="checkbox" name="gender" [(ngModel)]="insertITSlabDetails.maleApplicable"
                                                     > Male
                                                </div>
                                                <!-- <span class="help-text text-danger">Applicable For

                                                </span> -->
                                            </div>
                                               </div>
                                        </div>
                                        <!-- <div class="clearfix"> </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!-- </div> -->
                <!-- </div>
            </div>
        </div> -->

        <!---IT master table for edit(Master Sub Details)-->


        <!-- <div class="row"> -->
            <!-- <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="panel-group1" id="accordion3"> -->
                            <div class="panel panel-default mb-4 overflow-hidden br-7">
                                <div class="panel-heading1">
                                    <h4 class="panel-title1">
                                        <a class="accordion-toggle collapsed" data-bs-toggle="collapse"
                                            data-parent="#accordion" href="#collapseThree" aria-expanded="false">IT
                                            Master Sub Details</a>
                                    </h4>
                                </div>

                                <div id="collapseThree" class="panel-collapse collapse" role="tabpanel"
                                    aria-expanded="false">
                                    <div class="panel-body m-0 p-0">
                                        <div class="card-body m-0 p-0">
                                            <div class="row col-md-12 col-sm-12 col-xs-12 m-0 p-0">
                                                <!-- w-100 d-flex -->
                                                <div class="showGrid mt-2 ms-2 mx-2">
                                                    <div class="">
                                                        <label for="Salary Lower Limit" class="label_css form-label">
                                                            Lower Limit</label>
                                                    </div>
                                                    <div class="">
                                                        <label for="Salary Upper Limit" class="label_css form-label">
                                                            Upper Limit</label>
                                                    </div>
                                                    <div class="">
                                                        <label for="Percentage / Amount" class="label_css form-label">
                                                            Edu Cess %
                                                        </label>
                                                    </div>

                                                    <div class="d-flex align-items-end">
                                                        <div class="col-md-12 col-xs-12">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>

                                                <form #subDetailsForm="ngForm" class="m-0 p-0">
                                                    <section class="showGrid m-2 p-0">
                                                        <div class="">
                                                            <input type="text" name="Salary_Lower_Limit_ied"
                                                                class="form-control col-md-12 col-xs-12"
                                                                autocomplete="off" placeholder="Enter Salary Lower Limit"
                                                                autofocus="true" required pattern="[0-9]*\.?[0-9]*"
                                                                [(ngModel)]="subDetailsInfo.sublowerlimit" />
                                                        </div>
                                                        <div class="">
                                                            <input type="text" name="Salary_Upper_Limit_ied"
                                                                class="form-control col-md-12 col-xs-12"
                                                                autocomplete="off" placeholder="Enter Salary Upper Limit"
                                                                autofocus="true" required pattern="[0-9]*\.?[0-9]*"
                                                                [(ngModel)]="subDetailsInfo.subupperlimit" />
                                                        </div>
                                                        <div class="">
                                                            <input type="text" name="Percentage_Amount_ied"
                                                                class="form-control col-md-12 col-xs-12"
                                                                autocomplete="off" placeholder="Enter Percentage "
                                                                autofocus="true" required pattern="[0-9]*\.?[0-9]*"
                                                                [(ngModel)]="subDetailsInfo.subeducesspercent" />
                                                        </div>


                                                        <div class="d-flex align-items-end">
                                                            <button type="button" (click)="addEmployeeRule()"
                                                                class="btn btn_fit mb-2"><span><svg
                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" fill="currentColor"
                                                                        class="bi bi-plus-lg" viewBox="0 0 16 16">
                                                                        <path fill-rule="evenodd"
                                                                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </section>
                                                </form>

                                                <ng-container *ngFor="let ed of employeeRuleDetail;let i = index;">
                                                    <ng-container *ngIf="ed.userId!='Del'">
                                                        <div class="showGrid m-0 p-0">
                                                            <div class="ms-2 disable w-100">
                                                                <input type="text"
                                                                    class="form-control col-md-12 col-xs-12"
                                                                    autocomplete="off"
                                                                    placeholder="Enter Salary Lower Limit"
                                                                    autofocus="true"
                                                                    [(ngModel)]="ed.sublowerlimit" />
                                                            </div>
                                                            <div class="ms-2 disable w-100">
                                                                <input type="text"
                                                                    class="form-control col-md-12 col-xs-12"
                                                                    autocomplete="off"
                                                                    placeholder="Enter Salary Upper Limit"
                                                                    autofocus="true" required
                                                                    [(ngModel)]="ed.subupperlimit" />
                                                            </div>
                                                            <div class="ms-2 disable w-100">
                                                                <input type="text"
                                                                    class="form-control col-md-12 col-xs-12"
                                                                    autocomplete="off" placeholder="Enter Percentage "
                                                                    [(ngModel)]="ed.subeducesspercent" autofocus="true"
                                                                    required />
                                                            </div>

                                                            <div class="ms-2 d-flex align-items-end">
                                                                <button type="button" (click)="deleteEmployeeRule(i)"
                                                                    class="btn btn_fit mb-2"><span><svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="16" height="16" fill="red"
                                                                            class="bi bi-trash" viewBox="0 0 16 16">
                                                                            <path
                                                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                                            <path
                                                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                                        </svg>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>

                                            <div class="clearfix"> </div>

                                        </div>
                                    </div>
                                </div>
                                

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- </div> -->

    </div>

    <div *ngIf="addOrUpdate && add_view_toggle === 'addMaster'"
    class="actionBar pe-4 ps-4">
    <div class="clearfix"> </div>
    <button class="btn btn-danger me-2" type="button" (click)="clear()">Clear</button>
    <button class="btn btn-success" type="submit" (click)="submit()">Save</button>
</div>

<div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> IT Slab Master Data will be deleted permanently. Are you sure you want to continue?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="deletemaster()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
            </div>

        </div>
    </div>
</div>


</div>