import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SalaryAdvanceService } from '../services/salary-advance.service';
import { SessionService } from '../services';
import { NgForm } from '@angular/forms';
import { salaryAdvance } from '../models/salaryAdvance';
declare var $: any;

@Component({
  selector: 'app-salary-advance-approve',
  templateUrl: './salary-advance-approve.component.html',
  styleUrls: ['./salary-advance-approve.component.css']
})
export class SalaryAdvanceApproveComponent implements AfterViewInit {
  constructor(private salaryAdvanceService: SalaryAdvanceService, private sessionservice: SessionService) { }

  @ViewChild('salaryadvanceform') salaryAdvanceForm: NgForm;
  @ViewChild('salaryadvanceListform') salaryadvanceListform: NgForm;
  ngOnInit(): void {
    this.getAllAdvApplies();
    this.getSalaryMaster();
  }

  ngAfterViewInit() {
    this.clearForm();
  }


  searchattendance: string;
  salaryAdvanceList: any[] = [];
  tempsalaryAdvanceList: any[] = [];
  selectedList: any[] = [];
  salaryMaster: any[] = [];
  installmentComp = [];

  amount: number;
  totalAmount: number;
  noOfInstallment: number;

  expenseType: any[] = [];

  // salaryAdvance = salaryAdvance;

  date_of_loan: any;
  Ins_StartDate: any;
  model: any = {};
  isEdit: boolean = false;
  submitted: boolean;
  salaryAdvanceData = new salaryAdvance();
  salaryHeadId: any;
  salaryDetailId: any;
  interestRate: any;
  detailMasterSection: any;
  searchAdv: any;
  checkheader: boolean = false;
  checkDetail: boolean = false;
  dateError: boolean = false;
  isOnlyView:boolean = false;

  getAllAdvApplies() {

    let uniqueEmpId = this.sessionservice.getempid();
    this.salaryAdvanceService.getAllAdvApplies().subscribe(
      data => {
        console.log("Saved data : ", data);
        // this.salaryAdvanceList = data.salaryAdvanceList;
        this.tempsalaryAdvanceList = data.salaryAdvanceList;
        console.log("this.salaryAdvanceList  : ", this.salaryAdvanceList);
      }, error => {

      });
  }


  search(id) {
    // this.getregularizedinfo();
   
    if (this.searchAdv != undefined && this.searchAdv.length > 0) {
			console.log("call filter function ");
      console.log("this.salaryAdvanceList",this.salaryAdvanceList);
			this.salaryAdvanceList = this.tempsalaryAdvanceList.filter(item =>
			item.employeeName.toLowerCase().includes(this.searchAdv.toLowerCase()));
			console.log("this.searchAdv",this.searchAdv);
		}else {
      console.log("tempsalaryAdvanceList",this.tempsalaryAdvanceList);
			this.salaryAdvanceList = this.tempsalaryAdvanceList;
		}
   console.log("this.salaryAdvanceList=========>",this.salaryAdvanceList);
  }

  validateDates() {
    if (this.date_of_loan && this.Ins_StartDate) {
      const loanDate = new Date(this.date_of_loan);
      const startDate = new Date(this.Ins_StartDate);
      
      if (loanDate >= startDate) {
        this.dateError = true; // Flag for error handling
      } else {
        this.dateError = false;
      }
    }
    if(this.dateError){
      return false;
    }
    return true;
  }


  checkallbox(event:any) {
    this.salaryAdvanceList.forEach((exp) => {
      if(exp.status === 'PENDING'){
        exp.checked = event.target.checked;
  
        // Add or remove from selectedList based on checked status
        if (exp.checked) {
          this.addToSelectedList(exp);
        } else {
          this.removeFromSelectedList(exp);
        }
      }

    });
    console.log("expense list",this.salaryAdvanceList);
    console.log("expense list",this.selectedList);
   }

  check_info(sal: any, event: any): void {
    sal.checked = event.target.checked;
    if (sal.checked) {
      this.addToSelectedList(sal);
    } else {
      this.removeFromSelectedList(sal);
    }
    console.log("expense list", sal.checked);
    console.log("expense list", sal);
    console.log("Selected list", this.selectedList);
  }

  addToSelectedList(exp: any): void {
    // Check if the item is not already in the selectedList before adding
    if (!this.selectedList.includes(exp)) {
      this.selectedList.push(exp);
    }
  }

  removeFromSelectedList(exp: any): void {
    // Remove the item from the selectedList if it exists
    this.selectedList = this.selectedList.filter((item) => item !== exp);
  }

  approvalhandler() {
    let flag = true
    this.checkheader = false;
    if (this.selectedList.length === 0) {
      this.alertmessages("Please select item for Approval.", "danger")

      flag = false
      return;
    }
    
    this.selectedList.forEach(t => {
      if (t.salaryHeadId === undefined || t.salaryHeadId<=0) {
        console.log("t===============>",t);
        this.checkheader = true;
   
        return;
      }
      if (t.salaryDetailId === undefined || t.salaryDetailId<=0) {
        console.log("t===============>",t);
        this.checkDetail = true;
   
        return;
      }
    })
    if ( this.checkheader || this.checkDetail) {
      this.alertmessages("Salary Header and Salary Details are required.", "danger")
      return;
    }

    this.salaryAdvanceService.approveList(this.selectedList).subscribe(
      data => {
        console.log("Saved data : ", data)
        console.log("filteredExpenseList", this.selectedList);
        this.getAllAdvApplies()
        this.selectedList = [];
        this.alertmessages("Successfully Approved ", "success");
      }, error => {
        console.log(error);
        this.alertmessages("Please try again later", "danger");

      }

    );


  }

  rejecthandler() {
    let flag = true
    if (this.selectedList.length === 0) {
      this.alertmessages("Please select item for Approval.", "danger")

      flag = false
      return;
    }
    this.salaryAdvanceService.rejectList(this.selectedList).subscribe(
      data => {
        console.log("Saved data : ", data)
        console.log("filteredExpenseList", this.selectedList);
        this.getAllAdvApplies()
        this.selectedList = [];
        this.alertmessages("Successfully Rejected ", "success");
      }, error => {
        console.log(error);
        this.alertmessages("Please try again later", "danger");

      }
    );
  }

  editcalcInstallment() {
    this.submitted = true;
    console.log("uniqueEmpid", this.sessionservice.getempid())
    if (this.noOfInstallment > 0 && this.totalAmount > 0 && this.date_of_loan && this.Ins_StartDate) {

      this.setSalaryAdvanceModel();
      console.log(" this.salaryAdvanceData.installmentComp =>", this.salaryAdvanceData.installmentComp);
      this.installmentComp = this.model.installmentComp;
    }
  }

  update() {
    
    if (this.validate()) {
      if (this.noOfInstallment > 0 && this.totalAmount > 0 && this.date_of_loan && this.Ins_StartDate ) {
        this.setSalaryAdvanceModel();
      }
      this.salaryAdvanceService.updateSalaryAdvanceApprove(this.model).subscribe(
        data => {

          this.getAllAdvApplies();
          this.clear();
          this.clearForm();
          this.alertmessages("Updated successfully.", "success");
        }, error => {
          this.alertmessages("Error occured . Please try again Later.", "danger");
        });
    }
  }

  validate(): any {
    if (this.totalAmount == null || this.totalAmount == undefined) {
      return false;
    }
    if (this.noOfInstallment == null || this.noOfInstallment == undefined) {
      return false;
    }
    if (this.date_of_loan == null || this.date_of_loan == undefined) {
      return false;
    }
    if (this.Ins_StartDate == null || this.Ins_StartDate == undefined) {
      return false;
    }
    if(!this.validateDates()){
      return false;
    }
    if (this.model.installmentComp == null || this.model.installmentComp == undefined ) {
      return false;
    }
    if(this.model.installmentComp && this.model.installmentComp.length === 0){
      return false;
    }

    return true;
  }

  setSalaryAdvanceModel() {

    this.model.totalAmount = this.totalAmount;
    this.model.noOfInstallment = this.noOfInstallment;

    let currentDate = new Date(this.Ins_StartDate);
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');  // Months are 0-based, so add 1
    let day = currentDate.getDate().toString().padStart(2, '0');

    this.model.installStartDate = `${year}-${month}-${day}`;
    this.model.installStartMonth = this.Ins_StartDate;

    let currentDate2 = new Date(this.date_of_loan);
    let year2 = currentDate2.getFullYear();
    let month2 = (currentDate2.getMonth() + 1).toString().padStart(2, '0');  // Months are 0-based, so add 1
    let day2 = currentDate2.getDate().toString().padStart(2, '0');

    this.model.dateOfLoan = `${year2}-${month2}-${day2}`;

    this.model.salaryHeadId = this.salaryHeadId;
    this.model.salaryDetailId = this.salaryDetailId;
    this.model.interestRate = this.interestRate;
    this.model.uniqueEmpId = this.sessionservice.getempid();
    this.model.status = this.salaryAdvanceData.status;

  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/employeemaster']);
      });


    }, 5000);
  }

  clearForm() {
    if (this.salaryAdvanceForm) {
      console.log("Resetting form", this.salaryAdvanceForm);
      this.salaryAdvanceForm.reset(); // Reset form only after view initialization
    } else {
      console.log("Form is not initialized yet.");
    }
  }

  clear() {
    this.totalAmount = null;
    this.noOfInstallment = null;
    this.date_of_loan = null;
    this.Ins_StartDate = null;
    this.model.installmentComp = [];
    this.isEdit = false;
    this.submitted = false;
    this.clearForm();
    this.checkheader = false;
    this.isOnlyView = false;
    // this.salaryAdvanceForm.reset();
  }

  editSalaryAdvance(salAdvobj: any) {
    this.isEdit = true;
    this.totalAmount = salAdvobj.totalAmount;
    this.noOfInstallment = salAdvobj.noOfInstallment;
    const dbDate = salAdvobj.installStartDate.toString();

    this.Ins_StartDate = this.extractYearMonth(dbDate);

    const dOLDate = salAdvobj.dateOfLoan.toString();
    this.date_of_loan = this.extractYearMonth(dOLDate);
    this.salaryHeadId = salAdvobj.salaryHeadId;
    this.headChange(this.salaryHeadId);
    this.salaryDetailId = salAdvobj.salaryDetailId;
    this.interestRate = salAdvobj.interestRate;

    this.salaryAdvanceService.getapplyAdvInfoForApprover(salAdvobj.interMasterId).subscribe(
      data => {

        this.salaryAdvanceData = data.salaryAdvance;
        this.model.interMasterId = data.salaryAdvance.interMasterId;
        this.model.empInstMasterId = data.salaryAdvance.empInstMasterId;
        this.model.installmentComp = this.salaryAdvanceData.installmentComp;
        if(this.salaryAdvanceData.status!="APPROVED"){
          this.isOnlyView = true;
        }else{
          this.isOnlyView = false;
        }
  
      },
      error => {
        const response = error.error;
        console.log(error);
      }
    );

  }

  extractYearMonth(dateString: string): string {
    const date = new Date(dateString); // Convert to a Date object
    const year = date.getFullYear();   // Get the year
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (zero-based)

    return `${year}-${month}`; // Return in 'YYYY-MM' format
  }

  getSalaryMaster() {
    this.salaryAdvanceService.getSalaryDetails().subscribe((data) => {
      console.log(data);
      this.salaryMaster = data.salaryHeadMaster;
    }, (error) => {
      console.log(error);
    });
  }

  headChange(info: any) {
    console.log("head change ");
    console.log("salaryDetailMaster",this.salaryMaster);
    if(info > 0){
    this.detailMasterSection = this.salaryMaster.find(head => head.salaryHeadId == info).salaryDetailMaster;
    }
    console.log(this.detailMasterSection);
  }

  ListheadChange(info: any,sal:any) {
    console.log("salaryDetailMaster",this.salaryMaster);
    if(info > 0){
      sal.detailMasterSection = this.salaryMaster.find(head => head.salaryHeadId == info).salaryDetailMaster;
    }
    console.log(this.detailMasterSection);
  }

	chkInput() {
		console.log("chkinput ", this.searchAdv);
		if (this.searchAdv.length > 3) {
			console.log("call filter function ");
      console.log("this.salaryAdvanceList",this.salaryAdvanceList);
			this.salaryAdvanceList = this.salaryAdvanceList.filter(item =>
			item.employeeName.toLowerCase().includes(this.searchAdv.toLowerCase()));
			console.log("this.searchAdv",this.searchAdv);
		}
		else {
			this.salaryAdvanceList = this.tempsalaryAdvanceList;
		}
	}

  onScroll(){
    
  }

  ok() {
    console.log("this.installmentComp",this.installmentComp);
    const tolerance = 0.10; // Allow a small margin for rounding errors
    const totalInstallmentAmount:number = this.installmentComp.reduce((sum:number, comp) => {
      return sum + parseFloat(comp.instAmount); // Ensuring instAmount is a number
    }, 0);
    console.log("totalAmount",this.totalAmount);
    console.log("totalInstallmentAmount",totalInstallmentAmount);
    if (Math.abs( this.totalAmount - totalInstallmentAmount ) < tolerance) {
      console.log("The sum of installment amounts is approximately equal to the total installments.");
      this.model.installmentComp = this.installmentComp;
      $('#calInstallmentModel').modal('hide');
    } else {
      this.customalertmessages("The sum of installment amounts does NOT match the total amount.", "danger",'.alertmodel');
      console.log("The sum of installment amounts does NOT match the total installments.");
    }

  }
  customalertmessages(message: string, sign: string,alertcomponent: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector(alertcomponent);
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/employeemaster']);
      });


    }, 5000);
  }


}
