import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IncometaxcalculatorServiceService {
  protected basePath = '/api';
	public defaultHeaders: Headers = new Headers();
	public url;

	constructor(private httpclient: HttpClient,private service: CommonService) {}
  public getEmployeeList(empId: any,branch:any,year:any): Observable<any> {
    this.url = '/v1/getEmployee';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('empId', empId);
    queryParameters = queryParameters.set('branch', branch);
    queryParameters = queryParameters.set('year', year);
    return this.service.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getSavingMaster(empId: any,branch:any,year:any): Observable<any> {
    this.url = '/v1/getSavingMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('empId', empId);
    queryParameters = queryParameters.set('branch', branch);
    queryParameters = queryParameters.set('year', year);
    return this.service.getByQueryParamWithHttpInfo(queryParameters, path);
  }

}
