import { Component,OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AppService, SessionService, ConstantService } from '../../services/index';
declare var $: any;
@Component({
	// moduleId: module.id,
	templateUrl: './login.component.html',
	selector: 'login'
})

export class LoginComponent implements OnInit {

	submitted: boolean;
	loading = false;
	public loginData = { username: '', password: '' };

	loader = '';

	alertMsg: string;
	isDiscontinue: boolean;

	reset: boolean;
	rusername: string;
	forgotPassword: boolean;
	suberroralertMsg: string;
	subsuccessalertMsg: string;

	organizer: string;

	constructor(private service: AppService, private sessionService: SessionService,
		private router: Router, private constantService: ConstantService) { }

	ngOnInit() {
		console.log("Trying to Login");
	}

	checkIfAuthenticated() {
		const token = this.sessionService.getAuthToken();
		if (token) {
			this.router.navigate(['/customers/customersearch']);

		}
	}

	login(valid: boolean) {
		this.isDiscontinue = false;
		this.submitted = true;
		this.alertMsg = '';

		if (!valid) {
			return;
		}

		this.loading = true;

		this.service.obtainAccessToken(this.loginData).subscribe(
			data => {
				this.sessionService.setAuthToken(data.authToken);
				sessionStorage.setItem('companyId', data.companyId);

				sessionStorage.setItem('permission', JSON.stringify(data.rolePermissionList));
				this.submitted = false;
				this.loginData.username = this.loginData.username.trim();
				sessionStorage.setItem('username', this.loginData.username);
				const user = this.loginData.username.indexOf('@');
				if (user > 0) {
					sessionStorage.setItem('displayUser', this.loginData.username.substring(0, user))
				}

				// sujata code starts to get the role of logged in user
				this.service.getRoleOfLoggedUser(sessionStorage.getItem('username')).subscribe(
					data => {
						console.log(data.user.profilebyte)
						sessionStorage.setItem('id', data.user.id);
						sessionStorage.setItem('role', data.user.role);
						sessionStorage.setItem('priority', data.user.priorityNo);
						sessionStorage.setItem('empId', data.user.empId);
						sessionStorage.setItem('locName', data.user.locationName);
						sessionStorage.setItem('locId', data.user.locId);
						sessionStorage.setItem('displayUser', data.user.displayName)
						sessionStorage.setItem('branchId', data.user.branchId)
						if (data.user.discontinue) {
							this.isDiscontinue = true;
							return;
						}
						this.service.viewProfilePic(data.user.profilepath).subscribe(
							data => {
								console.log("profile paath", data)
								let blob: Blob = data;
								if (blob && blob.size != 0) {
									var downloadUrl = URL.createObjectURL(data);
									var reader = new FileReader();
									reader.readAsDataURL(data);
									reader.onload = (_event) => {
										let photourl = reader.result;
										// console.log(this.url)
										this.service.setprofile(photourl);
										sessionStorage.setItem("photourl", JSON.stringify(photourl));
									}
								} else {
									this.service.setprofile(null);
								}


							});


						if (JSON.parse(sessionStorage.getItem("permission")).includes(this.constantService.getRolePermissions()['DASHBOARD'])) {
							this.router.navigate(['/dashboard']);
						}
						else if (JSON.parse(sessionStorage.getItem("permission")).includes(this.constantService.getRolePermissions()['CUSTOMERSEARCH'])) {
							this.router.navigate(['/customers/customersearch']);
						}
						else if (JSON.parse(sessionStorage.getItem("permission")).includes(this.constantService.getRolePermissions()['DOCMASTER'])) {
							this.router.navigate(['/document']);
						}
						else if (JSON.parse(sessionStorage.getItem("permission")).includes(this.constantService.getRolePermissions()['MYDOCUMENTS'])) {
							this.router.navigate(['/document/myDocuments']);
						}
						else if (JSON.parse(sessionStorage.getItem("permission")).includes(this.constantService.getRolePermissions()['USER'])) {
							this.router.navigate(['/people']);
						}
						else {
							this.router.navigate(['/chpwd']);
						}

					},
					error => {
						const err = error.error;
						console.log(err.error);
					});


				// sujata code ends to get the role of logged in user
				this.loading = false;


				// setTimeout(() => {
				// 	this.loading = false;
				// 	this.router.navigate(['/scanner']);
				// }, 2000);
			},
			error => {
				const err = error.error;
				this.alertMsg = 'Please verify your user name or password.';
				this.submitted = false;
				this.loading = false;
			});

	}
	clearsubalertMsg() {
		this.suberroralertMsg = '';
		this.subsuccessalertMsg = '';
	}
	forgotpassword() {
		const rusername = (<HTMLInputElement>document.getElementById('rusername')).value
		this.suberroralertMsg = '';
		this.subsuccessalertMsg = '';
		this.service.emailPassword(rusername).subscribe(
			data => {
				this.forgotPassword = data.forgotPassword;
				this.subsuccessalertMsg = 'Sent the Reset Password to your registered EmailId ';
			},
			error => {
				const err = error.error;
				this.suberroralertMsg = 'Please Verify your Username ';

			});
	}

	getcurrentURL(): string {
		if (window.location.hostname === 'organizer.scaleupglobe.com') {
			return this.organizer = "Sign In to your Organizer Portal"
		}
	}



	showPassword() {
		const passwordelement = document.querySelector('#Password-toggle input');
		const element = document.querySelector('.displayhidepassword');
		console.log(element)
		if (document.querySelector('#Password-toggle input').getAttribute("type") === "text") {

			passwordelement.setAttribute('type', 'password')
			element.classList.add('fe-eye-off')
			element.classList.remove('fe-eye')
		} else if (document.querySelector('#Password-toggle input').getAttribute("type") === "password") {

			passwordelement.setAttribute('type', 'text')
			element.classList.remove('fe-eye-off')
			element.classList.add('fe-eye')
		}



		// $("#Password-toggle a").on('click', function (event) {
		// 	event.preventDefault();
		// 	if ($('#Password-toggle input').attr("type") == "text") {
		// 		$('#Password-toggle input').attr('type', 'password');
		// 		$('#Password-toggle i').addClass("fe-eye-off");
		// 		$('#Password-toggle i').removeClass("fe-eye");
		// 	} else if ($('#Password-toggle input').attr("type") == "password") {
		// 		$('#Password-toggle input').attr('type', 'text');
		// 		$('#Password-toggle i').removeClass("fe-eye-off");
		// 		$('#Password-toggle i').addClass("fe-eye");
		// 	}
		// });
	}

}
