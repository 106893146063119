import { Component, OnInit } from "@angular/core";
import { GlobalConstants } from "../constants/GlobalConstants";
import { Employeeswipeintermediateinfo } from "../models/EmployeeswipeIntermediateinfo";
import { TaskDetailsForAttendance } from "../models/TaskDetailForAttendance";
import { ApproveAttendancePipePipe } from "../pipes/approve-attendance-pipe.pipe";
import { EmployeeattendenceserviceService } from "../services/employeeattendenceservice.service";
import { DatePipe, TitleCasePipe } from "@angular/common";
import { EmployeeAttendence } from "../models/EmployeeAttendenceInfo";

declare var $: any;
@Component({
  selector: "app-attendance-aprroval",
  templateUrl: "./attendance-aprroval.component.html",
  styleUrls: ["./attendance-aprroval.component.css"],
})
export class AttendanceAprrovalComponent implements OnInit {

  userUniqueid: any;
  emp_attendence_list: any;
  swipeDate: any;

  swipeintype = GlobalConstants.SWIPE_IN;
  swipeouttype = GlobalConstants.SWIPE_OUT;

  taskdetails: TaskDetailsForAttendance[];
  regularizeList: Employeeswipeintermediateinfo[];

  approve: string = "APPROVED";
  rejected: string = "REJECTED";
  pending: string = "PENDING";

  tasklist_for_approval: TaskDetailsForAttendance[] = [];

  justACount: number = 0;
  tasklist_for_approval_backup: TaskDetailsForAttendance[] = [];

  searchattendance: string;
  currentdate: any;


  attendanceDetails: Employeeswipeintermediateinfo[] = [];
  tmpAttendanceDetails: Employeeswipeintermediateinfo[] = [];
  attendanceDetails_for_approval: Employeeswipeintermediateinfo[] = [];
  attendanceDetails_for_approval_backup: Employeeswipeintermediateinfo[] = [];

  constructor(
    private empaattendanceservice: EmployeeattendenceserviceService, private empAttendenceService: EmployeeattendenceserviceService, private datepipe: DatePipe,
    private attendancepipe: ApproveAttendancePipePipe, private titleCase: TitleCasePipe
  ) { }

  ngOnInit(): void {
    // get all the swipe infos for approval
    // this.getregularizedinfo();
  }

  search() {
    this.getregularizedinfo();
  }

  getregularizedinfo() {
    this.empaattendanceservice.get_regularizedinfo().subscribe((data) => {
      // old 
      // console.log(data, "get_regularizedinfo");
      // this.taskdetails = data.taskdetailsforattendance;
      // $('html,body').animate({
      //   scrollTop: $(`#navigatedest`).offset().top - 90
      // },
      //   'slow');
      // this.tasklist_for_approval = [];
      // console.log(this.taskdetails);


      // new
      console.log(data.employeeAttendanceList, "get_regularizedinfo");
      this.attendanceDetails = data.employeeAttendanceList;

      this.attendanceDetails.forEach(item => {
        item.status = this.titleCase.transform(item.status);
      })

      this.tmpAttendanceDetails = this.attendanceDetails;




      $('html,body').animate({
        scrollTop: $(`#navigatedest`).offset().top - 90
      },
        'slow');
      this.attendanceDetails_for_approval = [];
      console.log(this.taskdetails);
    });
  }

  //new 
  check_info(info: Employeeswipeintermediateinfo, event) {
    console.log("individualcheck box attendanceDetails_for_approval ", this.attendanceDetails_for_approval);
    if (event.target.checked) {
      this.attendanceDetails_for_approval.push(info)
      console.log("attendanceDetails_for_approval checked ", this.attendanceDetails_for_approval);
    } else {
      this.attendanceDetails_for_approval = this.attendanceDetails_for_approval.filter(task => task.swipeinfoid !== info.swipeinfoid)
      console.log("attendanceDetails_for_approval uncheked ", this.attendanceDetails_for_approval);
    }
  }

  checkallbox(e) {
    console.log("grouped check box attendanceDetails_for_approval ", this.attendanceDetails_for_approval);
    console.log("this.searchattendance", this.searchattendance);

    const checkboxes = document.querySelectorAll("input");
    if (e.target.checked) {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == "checkbox") {
          checkboxes[i].checked = true;
        }
      }
      this.attendanceDetails_for_approval.splice(0, this.attendanceDetails_for_approval.length)
      if (this.searchattendance) {
        this.filteredAttendance.forEach(t => {
          this.attendanceDetails_for_approval.push(t);
        })
      } else {
        this.attendanceDetails.forEach(t => {
          this.attendanceDetails_for_approval.push(t)
        })
      }
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == "checkbox") {
          checkboxes[i].checked = false;
        }
      }
      this.attendanceDetails_for_approval.splice(0, this.attendanceDetails_for_approval.length)
    }
    console.log(this.attendanceDetails_for_approval, "task details for approval ");
  }

  approvalhandler() {
    let flag = true
    const fg = "APPROVED";
    if (this.attendanceDetails_for_approval.length === 0) {
      this.alertmessages("Please choose the records you want to approve", "danger")
      flag = false
      return;
    }
    if (flag) {
      this.empaattendanceservice.approve(this.attendanceDetails_for_approval, fg).subscribe(
        data => {
          this.alertmessages("Succesfully Approved", "success")
          this.getregularizedinfo()
        }
      )
    }
  }

  rejecthandler() {
    let flag = true
    const fg = "REJECTED";
    if (this.attendanceDetails_for_approval.length === 0) {
      this.alertmessages("Please choose the records you want to reject.", "danger")
      flag = false
      return;
    }
    console.log(this.attendanceDetails_for_approval)
    let checkremark = false;
    this.attendanceDetails_for_approval.forEach(t => {
      if (t.remark === undefined) {
        console.log(t);
        checkremark = true;
        flag = false;
        return;
      }
    })
    if (checkremark) {
      this.alertmessages("Before Rejecting , kindly enter a comment ", "danger")
      return;
    }
    // we are passing flag so no need to modify the arry from client side and then update it in backend ..
    // this.attendanceDetails_for_approval.forEach(task => {
    //   if (task.status.toLowerCase() === this.pending.toLowerCase()) {
    //     task.status = this.rejected;
    //   }
    // });
    if (flag) {
      this.empaattendanceservice.approve(this.attendanceDetails_for_approval, fg).subscribe(data => {
        console.log(data)
        this.alertmessages("Succesfully Rejected", "success")
        this.getregularizedinfo()
      })
    }
  }

  //new
  filteredAttendance: Employeeswipeintermediateinfo[] = []
  getsearchtext(value: string) {
    console.log("get search text ", this.searchattendance);
    console.log("get search text length ", this.searchattendance.length);

    if (this.searchattendance.length > 3) {
      this.attendanceDetails = this.attendanceDetails.filter(item =>
        item.employeeName.toLowerCase().includes(this.searchattendance.toLowerCase())
      )
    } else {
      this.attendanceDetails = this.tmpAttendanceDetails;
    }

  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/employeeattendenceapproval']);
      });


    }, 3000);
  }

  get_Attendence_info(info, i) {

    console.log("info ",info[i]);
    
    this.userUniqueid = info[i].employeeId;
    this.swipeDate = info[i].swipetime;

    // this.userUniqueid = info[i].employeeswipeinfointermediate.empid;
    // this.swipeDate = info[i].employeeswipeinfointermediate.swipetime;

    this.currentdate = this.datepipe.transform(this.swipeDate, 'MMM d');

    this.empAttendenceService.get_Attendence_info_based_On_date_Regularize(this.userUniqueid, this.datepipe.transform(this.swipeDate, 'yyyy-MM-dd')).subscribe(
      data => {
        this.emp_attendence_list = data.currentattendencelistbasedonDateRegularize;
        console.log(this.emp_attendence_list, "emp swipe info for a selected date")
        this.displayclockIn_and_clockOut(this.emp_attendence_list)

      }
    );
  }

  displayclockIn_and_clockOut(empswipelist: EmployeeAttendence[]) {
    if (empswipelist.length !== 0) {
      const lastswipeType: number = empswipelist[empswipelist.length - 1].swipetype;
      const clock_In = document.getElementById('clockIN');
      const clock_Out = document.getElementById('clockOut');

      const element_has_displaynone_class = document.querySelector('.displayNone');
      element_has_displaynone_class.classList.remove('displayNone')

      console.log(lastswipeType, "lastswipe type")
      if (lastswipeType === GlobalConstants.SWIPE_IN) {
        // display static clock
        if (clock_In.classList.contains('displayNone')) {
          clock_In.classList.remove('displayNone');

        }
        else {
          clock_In.classList.add('displayNone')
        }
      } else if (lastswipeType === GlobalConstants.SWIPE_OUT) {
        // display rotating clock indication that employee has clocked in 
        if (clock_Out.classList.contains('displayNone')) {
          clock_Out.classList.remove('displayNone');

        } else {
          clock_Out.classList.add('displayNone');
        }

      }

    }
  }

}
